<template>
  <svg :width="size" :height="size" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.75 9.5L12.75 15.5L18.75 9.5" fill="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: 'ChevronDownIcon',
  props: {
    size: {
      type: String,
      default: '1.5rem',
    },
  },
};
</script>
