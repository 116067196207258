import api from '@/services/api';

const store = {
  namespaced: true,
  state: {
    customers: [],
  },
  mutations: {
    UPDATE_CUSTOMERS(state, payload) {
      state.customers = payload;
    },
  },
  actions: {
    async getCustomers(context) {
      try {
        const response = await api.get('/api/public/partner/customers');
        context.commit('UPDATE_CUSTOMERS', response.data.data);
      } catch (err) {
        this.$toast.error('Não foi possível recuperar os clientes!');
      }
    },
  },
};

export default store;
