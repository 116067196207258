<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M2.99994 12.9999H18.5899L13.2899 18.2899C13.1962 18.3829 13.1218 18.4935 13.0711 18.6154C13.0203 18.7372 12.9941 18.8679 12.9941 18.9999C12.9941 19.132 13.0203 19.2627 13.0711 19.3845C13.1218 19.5064 13.1962 19.617 13.2899 19.7099C13.3829 19.8037 13.4935 19.8781 13.6154 19.9288C13.7372 19.9796 13.8679 20.0057 13.9999 20.0057C14.132 20.0057 14.2627 19.9796 14.3845 19.9288C14.5064 19.8781 14.617 19.8037 14.7099 19.7099L21.7099 12.7099C21.8037 12.617 21.8781 12.5064 21.9288 12.3845C21.9796 12.2627 22.0057 12.132 22.0057 11.9999C22.0057 11.8679 21.9796 11.7372 21.9288 11.6154C21.8781 11.4935 21.8037 11.3829 21.7099 11.2899L14.7099 4.28994C14.6167 4.1967 14.506 4.12274 14.3842 4.07228C14.2624 4.02182 14.1318 3.99585 13.9999 3.99585C13.7336 3.99585 13.4782 4.10164 13.2899 4.28994C13.1967 4.38318 13.1227 4.49387 13.0723 4.61569C13.0218 4.73751 12.9959 4.86808 12.9959 4.99994C12.9959 5.26624 13.1016 5.52164 13.2899 5.70994L18.5899 10.9999H2.99994C2.73472 10.9999 2.48037 11.1053 2.29283 11.2928C2.1053 11.4804 1.99994 11.7347 1.99994 11.9999C1.99994 12.2652 2.1053 12.5195 2.29283 12.707C2.48037 12.8946 2.73472 12.9999 2.99994 12.9999Z" fill="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: 'ArrowRightIcon',
  props: {
    size: {
      type: String,
      default: '1.5rem',
    },
  },
};
</script>
