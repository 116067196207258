<template>
  <div>
    <PageTitle :breadcrumbName="breadcrumbName" :pageTitle="pageTitle" class="mb-2"/>

    <div class="card columns gap2 mb-1">
      <div class="span-5">
        <h5 class="mb-1 flex ai-c jc-sb text-uppercase text-medium">
          RELATÓRIO GERAL DOS PROCESSOS DE IMPORTAÇÃO
        </h5>
        <p class="text-light text-md">Quantidade de Processos de Importação do Filtro:
          <strong>{{ processes.length }} processos</strong></p>
      </div>

      <div class="span-7 warning-bg lt-pd">
        <h5>
          <span class="text-md text-right">
            <span class="text-bold">
            Para que os Procesos de Importação sejam exibidos
            </span>
            <br/>
            Por Etapa: Digite no campo Pesquisa (Invoice, Ref Pedido, BL/AWB/CRT, Exportador, Fabricante e/ou Nº DI)
            Por Período: Selecione um campo de Data e um Período Inicial e Final.
          </span>
        </h5>
      </div>

      <div class="span-12 columns gap2">
        <div class="span-2">
          <Select v-model="tipoFiltro" :options="tiposFiltro" title="Tipo de Filtro"/>
        </div>
        <div v-if="tipoFiltro === 'filtroGeral'" class="span-4">
          <StyledInput v-model="generalSearchInput" class="rounded" name="generalSearch" placeholder="Digite a Palavra Chave" type="text"/>
        </div>
        <div v-if="tipoFiltro === 'porPeriodo'" class="span-3">
          <Select v-model="filtroCampo" :options="listOfDatesFields" :useIcon="true"
                  title="Tipo de Data">
            <template #icon>
              <CalendarIcon/>
            </template>
          </Select>
        </div>
        <div v-if="tipoFiltro === 'porPeriodo'" class="span-2">
          <Select v-model="filtroDataInicial" :useDate="true" :useIcon="true" title="Data Inicial">
            <template #icon>
              <CalendarIcon/>
            </template>
          </Select>
        </div>
        <div v-if="tipoFiltro === 'porPeriodo'" class="span-2">
          <Select v-model="filtroDataFinal" :useDate="true" :useIcon="true" title="Data Final">
            <template #icon>
              <CalendarIcon/>
            </template>
          </Select>
        </div>
        <div class="span-3 flex">
          <button v-if="tipoFiltro === 'filtroGeral'" class="btn medium solid primary" @click="submitSearchGeneralFilter">GERAR
          </button>
          <button v-else class="btn medium solid primary" @click="submitSearchDataFilter">GERAR
          </button>
          <download-excel v-if="processes.length > 0" :data="processes" :fields="fieldsExcel" class="btn medium solid success ml-1 btn-exportar flex" name="relatorio-processos-importacao.xls"> EXCEL
          </download-excel>
        </div>
      </div>
    </div>
    <div class="card columns gap2 mb-2">
      <div class="span-12">
        <Table :columns="columns" :data="processes"
               tableClass="vgt-table center text-md text-table text-medium sm-pd"/>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { mapState, mapActions } from 'vuex';

// eslint-disable-next-line import/no-cycle
import api from '@/services/api';
import Table from '@/components/Table.vue';
import PageTitle from '@/components/PageTitle.vue';
import StyledInput from '@/components/DataInput/StyledInput.vue';
import Select from '@/components/DataInput/Select.vue';

import * as labelsOfImportProcess from '@/utils/DateLabelsUtil';
import ProcessesReportColumnsExcel from '@/utils/ProcessesReportColumnsExcel';
import ModalProcess from '@/components/ModalProcess.vue';

import {
  // MapBoxIcon,
  CalendarIcon,
} from '@/components/Icons';

export default {
  components: {
    Table,
    PageTitle,
    StyledInput,
    Select,
    CalendarIcon,
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapState('process', ['processes']),
    listOfDatesFields() {
      const data = new Array(0);
      if (this.labels !== undefined) {
        // eslint-disable-next-line no-restricted-syntax
        for (const prop in this.labels) {
          if (this.labels[prop].type === 'date') {
            data.push(this.labels[prop]);
          }
        }
      }
      return data;
    },
  },
  data() {
    return {
      filtroEtapa: { label: 'Produção', value: 7 },
      generalSearchInput: '',
      filtroCampo: '',
      teste: '',
      filtroDataInicial: '',
      filtroDataFinal: '',
      tipoFiltro: 'filtroGeral',
      modalData: {},
      tiposFiltro: [
        {
          label: 'Palava Chave',
          value: 'filtroGeral',
        },
        {
          label: 'Por Período',
          value: 'porPeriodo',
        },
      ],
      labels: {
        id: {
          label: 'ID',
        },
        ...labelsOfImportProcess.labels[0],
      },
      columns: [
        {
          name: 'Ref',
          key: 'identification',
          width: 100,
          isDate: false,
        },
        {
          name: 'Etapa',
          key: 'step.description',
          isDate: false,
        },
        {
          name: 'Empresa',
          key: 'customer.name',
          isDate: false,
        },
        {
          name: 'Exportador',
          key: 'exporter.name',
          isDate: false,
        },
        {
          name: 'Ref Pedido',
          key: 'customerRef',
          isDate: false,
        },
        {
          name: 'Outras referências',
          key: 'anotherRef',
          isDate: false,
        },
        {
          name: 'Status',
          key: 'status',
          isDate: false,
        },
        {
          name: 'Invoice',
          key: 'invoice',
          isDate: false,
        },
        {
          name: 'Incoterm',
          key: 'incoterm',
        },
        {
          name: 'Forwarder',
          key: 'freightForwarder.name',
        },
        {
          name: 'CNTR',
          key: 'typeOfBoarding',
        },
        {
          name: 'Prontidão Prev',
          key: 'datesEstimatedGoodsReadinesDate',
          isDate: true,
        },
        {
          name: 'ETD',
          key: 'datesETD',
          isDate: true,
        },
        {
          name: 'ETA',
          key: 'datesETA',
          isDate: true,
        },
        {
          name: 'Embarque',
          key: 'datesDepartureDate',
          isDate: true,
        },
        {
          name: 'Chegada',
          key: 'datesArrivalDate',
          isDate: true,
        },
        {
          name: 'Nº DI',
          key: 'diNumber',
        },
        {
          name: 'Registro',
          key: 'diRegistryDate',
          isDate: true,
        },
      ],
      fieldsExcel: ProcessesReportColumnsExcel,
    };
  },
  props: {
    pageTitle: {
      type: String,
      default: '',
    },
    breadcrumbName: {
      type: String,
      default: '',
    },
  },
  methods: {
    ...mapActions('process', ['updateProcesses', 'getProcessDocuments']),
    ...mapActions(['toggleLoading']),
    async submitSearchGeneralFilter() {
      if (this.generalSearchInput !== '') {
        this.toggleLoading(true);
        const despachantes = await api.get(`/api/public/partner/getCompanies/${this.user.person.id}`);

        const despachante = despachantes.data.data[0];

        const route = `/api/public/partner/processes/general/search/${despachante.id}?searchValue=${this.generalSearchInput}`;
        const response = await api.get(route);
        const { data } = response.data;
        this.updateProcesses(data);
        this.toggleLoading(false);
      }
    },
    async submitSearchDataFilter() {
      this.toggleLoading(true);
      this.generalSearchInput = '';
      const despachantes = await api.get(`/api/public/partner/getCompanies/${this.user.person.id}`);

      const despachante = despachantes.data.data[0];

      if (this.filtroCampo !== '' && this.filtroDataFinal !== '' && this.filtroDataFinal) {
        const dataInicial = `${moment(this.filtroDataInicial, 'DD/MM/YYYY').format('Y-MM-DDT')}00:00:59`;
        const dataFinal = `${moment(this.filtroDataFinal, 'DD/MM/YYYY').format('Y-MM-DDT')}23:59:59`;

        const route = `/api/public/partner/processes/reportProcessByPeriod/${despachante.id}?field=${this.filtroCampo}&firstDate=${dataInicial}&secondDate=${dataFinal}`;

        const response = await api.get(route);

        const { data } = response.data;

        this.updateProcesses(data);

        this.toggleLoading(false);
      }
    },
  },
  mounted() {
    this.updateProcesses([]);
  },
  destroyed() {
    this.updateProcesses([]);
  },
};
</script>

<style scoped>
.div-buttons {
  margin-top: 4px;
}
.btn {
  height: 50px;
}
.warning-bg {
    background-color: #fffff0;
}
</style>
