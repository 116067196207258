<template>
  <transition name="drawer">
    <div class="drawer-overlay flex jc-fe" v-if="handler" @click.self="$emit('request-close', true)">
      <div class="drawer-container">
        <div class="drawer-header flex fgap2 ai-c jc-sb p-2">
          <h4 class="text-bold">{{ title }}</h4>
          <button class="btn solid primary small" type="button" @click="$emit('request-close', true)"> FECHAR </button>
        </div>
        <div class="drawer-content p-2">
          <slot name="content"></slot>
        </div>
        <div class="drawer-footer flex ai-c jc-c fgap2" v-if="this.$scopedSlots.footer">
          <slot name="footer"></slot>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'DrawerModal',
  props: {
    handler: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
  methods: {
    escapeListener(event) {
      if (event.key === 'Escape') {
        this.$emit('request-close', true);
      }
    },
  },
  mounted() {
    window.addEventListener('keydown', this.escapeListener);
  },
  beforeDestroy() {
    window.removeEventListener('keydown', this.escapeListener);
  },
};
</script>

<style scoped>
  .drawer-overlay {
    position: fixed;
    width: 100vw;
    height: 100vh;
    overflow: auto;
    top: 0;
    right: 0;
    z-index: 999999;
  }

  .drawer-container {
    background-color: var(--white-color);
    max-width: 800px;
    width: 100%;
    box-shadow: -5px 0 25px rgba(0,0,0,0.2);
  }

  .drawer-header {
    height: 60px;
    border-bottom: var(--input-border);
    box-shadow: 0px 5px 15px rgba(0,0,0,0.1);
  }

  .drawer-content {
    height: calc(100vh - 140px);
    overflow: auto;
  }

  .drawer-footer {
    height: 80px;
    border-top: var(--input-border);
    box-shadow: 0px -5px 15px rgba(0,0,0,0.1);
  }

  .drawer-enter-active,
  .drawer-leave-active {
    transition: var(--all-transition);
  }

  .drawer-enter,
  .drawer-leave-to {
    right: -100%;
  }

  .drawer-enter-to,
  .drawer-leave {
    right: 0;
  }
</style>
