<template>
  <svg :width="size" :height="size" viewBox="0 0 23 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M21.875 11.25H18.75V15L17.5 14.168L16.25 15V11.25H13.125C12.7812 11.25 12.5 11.5312 12.5 11.875V19.375C12.5 19.7188 12.7812 20 13.125 20H21.875C22.2188 20 22.5 19.7188 22.5 19.375V11.875C22.5 11.5312 22.2188 11.25 21.875 11.25ZM6.875 8.75H15.625C15.9688 8.75 16.25 8.46875 16.25 8.125V0.625C16.25 0.28125 15.9688 0 15.625 0H12.5V3.75L11.25 2.91797L10 3.75V0H6.875C6.53125 0 6.25 0.28125 6.25 0.625V8.125C6.25 8.46875 6.53125 8.75 6.875 8.75ZM9.375 11.25H6.25V15L5 14.168L3.75 15V11.25H0.625C0.28125 11.25 0 11.5312 0 11.875V19.375C0 19.7188 0.28125 20 0.625 20H9.375C9.71875 20 10 19.7188 10 19.375V11.875C10 11.5312 9.71875 11.25 9.375 11.25Z" fill="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: 'BoxesIcon',
  props: {
    size: {
      type: String,
      default: '1rem',
    },
  },
};
</script>
