<template>
  <svg :width="size" :height="size" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.6549 3.85871C11.5616 3.85871 9.83285 2.5 7.20109 2.5C6.22629 2.5 5.35309 2.67125 4.54309 2.96926C4.65534 2.67608 4.70312 2.36216 4.68316 2.04887C4.61395 0.938087 3.70336 0.0471108 2.59133 0.0018765C1.3416 -0.0489828 0.3125 0.949063 0.3125 2.1875C0.3125 2.9307 0.683477 3.58691 1.25 3.98223V19.0625C1.25 19.5803 1.66973 20 2.1875 20H2.8125C3.33027 20 3.75 19.5803 3.75 19.0625V15.375C4.8559 14.9037 6.23367 14.5109 8.22012 14.5109C10.3134 14.5109 12.0421 15.8696 14.6739 15.8696C16.5555 15.8696 18.0593 15.2331 19.4593 14.2735C19.7984 14.0411 20 13.6551 20 13.2439V3.74785C20 2.83406 19.052 2.22949 18.2232 2.61441C16.8819 3.23738 15.2368 3.85871 13.6549 3.85871Z" fill="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: 'FlagIcon',
  props: {
    size: {
      type: String,
      default: '1.5rem',
    },
  },
};
</script>
