<template>
  <svg :width="size" :height="size" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="8.10312" cy="8.10312" r="7.10312" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M12.9051 13.4124L16.4927 17" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'SearchIcon',
  props: {
    size: {
      type: String,
      default: '1.5rem',
    },
  },
};
</script>
