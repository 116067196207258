<template>
  <svg :width="size" :height="size" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.32007 6.17556L10.1094 7.03765C10.1401 7.05248 10.1731 7.05962 10.2066 7.05962C10.2396 7.05962 10.2731 7.05248 10.3036 7.03765L15.3885 4.59451C15.466 4.55743 15.5154 4.47858 15.5154 4.39287C15.5154 4.3066 15.466 4.22831 15.3885 4.19122L13.5995 3.33023C13.5382 3.30083 13.4668 3.30083 13.4055 3.33023L8.32062 5.77226C8.24342 5.80935 8.19397 5.88792 8.19397 5.97391C8.1937 6.05962 8.2426 6.13847 8.32007 6.17556Z" fill="currentColor"/>
    <path d="M7.46674 12.8615L12.7497 15.4052C12.7802 15.4201 12.8137 15.4275 12.8467 15.4275C12.8882 15.4275 12.9294 15.4162 12.9659 15.3931C13.031 15.3522 13.0706 15.2808 13.0706 15.2036V9.56099C13.0706 9.47528 13.0214 9.39643 12.9439 9.35934L7.66097 6.81594C7.59201 6.78242 7.50987 6.78709 7.44476 6.82775C7.37992 6.86869 7.34009 6.94039 7.34009 7.01731V12.6599C7.33981 12.7462 7.38899 12.8247 7.46674 12.8615ZM11.4742 10.8593C11.3764 11.0637 11.1118 11.1615 10.9019 11.0588L8.95492 10.1217C8.85218 10.0725 8.77471 9.98572 8.73679 9.87803C8.69861 9.76978 8.7052 9.65303 8.75493 9.54918C8.8258 9.4011 8.97745 9.3055 9.14091 9.3055C9.20575 9.3055 9.26839 9.32033 9.328 9.34918L11.2747 10.2857C11.3777 10.3354 11.4552 10.4223 11.4928 10.5305C11.531 10.6387 11.5244 10.7558 11.4742 10.8593Z" fill="currentColor"/>
    <path d="M13.9297 9.56099V15.2036C13.9297 15.2808 13.9692 15.3522 14.0341 15.3931C14.0706 15.4162 14.1118 15.4275 14.1533 15.4275C14.1866 15.4275 14.2198 15.4201 14.2506 15.4052L19.5335 12.8615C19.6113 12.8245 19.6605 12.7462 19.6605 12.6599V7.02006C19.6605 6.94286 19.6209 6.87198 19.5558 6.83105C19.4907 6.78956 19.4091 6.78572 19.3399 6.81841L14.0566 9.35934C13.9789 9.39643 13.9297 9.47528 13.9297 9.56099Z" fill="currentColor"/>
    <path d="M11.4886 7.56099C11.4886 7.64671 11.5378 7.72528 11.6153 7.76237L13.4032 8.6228C13.4337 8.63764 13.4669 8.64533 13.5002 8.64533C13.5332 8.64533 13.5667 8.63764 13.5972 8.6228L18.6807 6.18022C18.7581 6.14313 18.8073 6.06456 18.8073 5.97885C18.8073 5.89259 18.7581 5.81429 18.6807 5.7772L16.8944 4.91676C16.8334 4.88737 16.7623 4.88737 16.7005 4.91676L11.615 7.35934C11.5381 7.39643 11.4886 7.47473 11.4886 7.56099Z" fill="currentColor"/>
    <path d="M3.85657 9.81869C3.85657 15.0014 12.7192 24.2613 13.0964 24.653C13.2019 24.7626 13.3475 24.8245 13.4994 24.8245C13.6513 24.8245 13.797 24.7626 13.9024 24.653C14.2796 24.2613 23.1434 15.0014 23.1434 9.81869C23.1434 4.50165 18.8173 0.175278 13.4994 0.175278C8.18239 0.175552 3.85657 4.50165 3.85657 9.81869ZM13.4994 1.29423C18.2 1.29423 22.0244 5.11814 22.0244 9.81869C22.0244 13.9657 15.2415 21.5648 13.4994 23.4473C11.7579 21.5646 4.97552 13.9654 4.97552 9.81869C4.97552 5.11841 8.79943 1.29423 13.4994 1.29423Z" fill="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: 'MapBoxIcon',
  props: {
    size: {
      type: String,
      default: '1.5rem',
    },
  },
};
</script>
