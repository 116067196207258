<template>
  <div v-if="Object.keys(this.user.person).length > 0" class="flex ai-c ph-2 fgap2 pointer relative user-menu-wrapper" @click="menuOpen = !menuOpen">
    <div class="profile-picture flex ai-c jc-c">
      <div :class="['profile-image', user.person.photo ? '' : 'flex ai-c jc-c']">
        <img v-if="user.person.photo" alt="nome"
             src="https://randomuser.me/api/portraits/men/32.jpg">
        <svg v-else class="feather feather-user" fill="none" height="24" stroke="white"
             stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24"
             width="24" xmlns="http://www.w3.org/2000/svg">
          <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
          <circle cx="12" cy="7" r="4"></circle>
        </svg>
      </div>
    </div>

    <div class="user-info">
      <p class="text-primary text-bold text-bg">{{ user.person.name }}</p>
      <p class="text-light text-md company-name">{{ user.person.email }}</p>
    </div>

    <div class="chevron-indicator flex ai-c jc-c">
      <ChevronDownIcon class="text-primary"/>
    </div>

    <transition name="notifications">
      <ul v-if="menuOpen" class="user-menu">
        <li>
          <router-link :to="{ name: 'MyData' }">
            Meus Dados
          </router-link>
        </li>
        <li>
          <a href="#" @click.prevent="logout">Sair</a>
        </li>
      </ul>
    </transition>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

import { ChevronDownIcon } from '@/components/Icons';

export default {
  name: 'UserMenu',
  components: {
    ChevronDownIcon,
  },
  data() {
    return {
      menuOpen: false,
    };
  },
  computed: {
    ...mapState('auth', ['user']),
  },
  methods: {
    ...mapActions('auth', ['logout']),
  },
  mounted() {
    document.addEventListener('click', (event) => {
      if (!event.target.closest('.user-menu-wrapper')) {
        this.menuOpen = false;
      }
    });
  },
};
</script>

<style scoped>
.profile-picture {
  width: 3.5rem;
  height: 3.5rem;
  border-radius: var(--round-border-radius);
  background: linear-gradient(45deg, var(--accent-color), var(--primary-color));
}

.profile-image {
  width: calc(3.5rem - 8px);
  height: calc(3.5rem - 8px);
  border-radius: var(--round-border-radius);
  overflow: hidden;
  border: var(--large-border-width) solid var(--body-background);
}

.chevron-indicator {
  background-color: var(--light-bg-color);
  width: 1.75rem;
  height: 1.75rem;
  border-radius: var(--round-border-radius);
}

.user-menu {
  position: absolute;
  z-index: 999;
  top: calc(100% + 1rem);
  right: 0;
  min-width: 280px;
  background-color: var(--white-color);
  box-shadow: var(--box-shadow);
  border-radius: var(--medium-border-radius);
}

.user-menu li:not(:last-of-type) {
  border-bottom: var(--input-border);
}

.user-menu a {
  display: block;
  padding: 1rem;
}

.user-info .company-name {
  max-width: 200px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
