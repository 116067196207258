<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.0001 2.99994L11.0001 18.5899L5.71006 13.2899C5.6171 13.1962 5.5065 13.1218 5.38464 13.0711C5.26278 13.0203 5.13207 12.9941 5.00006 12.9941C4.86805 12.9941 4.73734 13.0203 4.61548 13.0711C4.49362 13.1218 4.38303 13.1962 4.29006 13.2899C4.19633 13.3829 4.12194 13.4935 4.07117 13.6154C4.0204 13.7372 3.99426 13.8679 3.99426 13.9999C3.99426 14.132 4.0204 14.2627 4.07117 14.3845C4.12194 14.5064 4.19633 14.617 4.29006 14.7099L11.2901 21.7099C11.383 21.8037 11.4936 21.8781 11.6155 21.9288C11.7373 21.9796 11.868 22.0057 12.0001 22.0057C12.1321 22.0057 12.2628 21.9796 12.3846 21.9288C12.5065 21.8781 12.6171 21.8037 12.7101 21.7099L19.7101 14.7099C19.8033 14.6167 19.8773 14.506 19.9277 14.3842C19.9782 14.2624 20.0042 14.1318 20.0042 13.9999C20.0042 13.7336 19.8984 13.4782 19.7101 13.2899C19.6168 13.1967 19.5061 13.1227 19.3843 13.0723C19.2625 13.0218 19.1319 12.9959 19.0001 12.9959C18.7338 12.9959 18.4784 13.1016 18.2901 13.2899L13.0001 18.5899L13.0001 2.99994C13.0001 2.73472 12.8947 2.48037 12.7072 2.29283C12.5196 2.1053 12.2653 1.99994 12.0001 1.99994C11.7348 1.99994 11.4805 2.1053 11.293 2.29283C11.1054 2.48037 11.0001 2.73472 11.0001 2.99994Z" fill="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: 'ArrowDownIcon',
  props: {
    size: {
      type: String,
      default: '1.5rem',
    },
  },
};
</script>
