<template>
  <div v-if="activeTab == 4">
    <div class="flex ai-c jc-sb fgap2">
      <div>
        <h4 class="text-bold">DOCUMENTOS</h4>
        <p>Total de {{ theDocs.length }} documentos cadastradas</p>
      </div>

      <div>
        <div class="flex ai-c fgap1 mb-1">
          <SearchIcon/>
          <p>Pesquisar Documentos</p>
        </div>
        <div class="search-docs-container relative">
          <Input
            :addStyle="`height: 35px; padding-right: 120px;`"
            class="search-docs-input"
            name="search"
            type="search"
          />
          <button class="btn solid primary" type="button">PESQUISAR</button>
        </div>
      </div>
    </div>

    <hr class="mb-2"/>

    <div
      v-if="!disableClick"
      :class="['upload-placeholder flex ai-c jc-c text-center mb-2', dragging && 'active']"
      @dragover.prevent="dragover"
      @dragleave.prevent="dragleave"
      @drop.prevent="drop"
    >
      <CloudIcon class="text-primary mr-2" size="4rem"/>
      <div class="pointer">
        <h2 class="text-primary mb-1">Enviar <strong>DOCS</strong></h2>
        <p class="text-uppercase text-primary">Puxe e Arraste os Documentos Aqui</p>
      </div>
    </div>

    <div v-if="documents.length > 0" class="table-overflow mb-2">
      <table class="docs-table">
        <thead>
        <th></th>
        <th>Nome do Arquivo</th>
        <th>Descrição</th>
        <th>Tipo de Documento</th>
        <th class="text-right">Ações</th>
        </thead>
        <tbody>
        <tr v-for="(document, index) in documents" :key="index">
          <td>{{ index + 1 }}</td>
          <td>{{ document.name }}</td>
          <td>
            <input v-model="documents[index].descricao" placeholder="Descrição" type="text"/>
          </td>
          <td>
            <select v-model="documents[index].tipoDocumento">
              <option value="">Selecione uma opção</option>
              <option v-for="(type) in documentTypes" :key="type.id" :value="type.id">
                {{ type.description }}
              </option>
            </select>
          </td>
          <td class="text-right">
            <a class="btn small solid danger" href="#" @click.prevent="removeDocument(index)">EXCLUIR</a>
          </td>
        </tr>
        </tbody>
      </table>

      <div class="flex ai-c jc-c mv-2">
        <a class="btn solid primary" href="#" @click.prevent="uploadFiles">Enviar Documentos</a>
      </div>
    </div>

    <div class="columns gap2">
      <div class="span-8">
        <DocumentsCard
          :docs="theDocs"
          :process="process"
          @refresh-documents="refreshDocuments"
          type="success"
        />
      </div>
      <div class="span-4">
        <DocumentsCard
          :missingDocs="missingDocs"
          type="danger"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

import {
  CloudIcon,
  SearchIcon,
} from '@/components/Icons';

import Input from '@/components/DataInput/Input.vue';
import DocumentsCard from '@/components/DocumentsCard.vue';
import api from '@/services/api';

export default {
  name: 'ModalProcessTabLogistics',
  components: {
    // Icons
    CloudIcon,
    SearchIcon,
    // Components
    Input,
    DocumentsCard,
  },
  data() {
    return {
      dragging: false,
      documents: [],
      documentTypes: [],
      theDocs: this.docs,
    };
  },
  created() {
    this.getDocumentTypes();
  },
  async mounted() {
    await this.getFileTypes();
    if (this.missingDocs.length > 0) {
      window.dispatchEvent(new CustomEvent('missingDoc'));
    }
  },
  computed: {
    requiredDocs() {
      return this.documentTypes.filter((doc) => doc.required === true);
    },
    missingDocs() {
      const miss = [];

      this.requiredDocs.map((reqDoc) => {
        if (!this.docs.find((doc) => doc.code === reqDoc.code)) {
          miss.push({
            title: reqDoc.description,
            status: 'missing',
          });
        }
        return reqDoc;
      });

      return miss;
    },
  },
  methods: {
    ...mapActions(['toggleLoading']),
    ...mapActions('process', ['getProcessDocuments', 'verifyDocActions']),
    async getDocumentTypes() {
      this.toggleLoading(true);
      const response = await api.get(`/api/public/partner/processes/${this.process.id}/documents/types`);
      this.documentTypes = response.data.data;
      this.toggleLoading(false);
    },
    dragover() {
      this.dragging = true;
    },
    drop(e) {
      // eslint-disable-next-line no-plusplus
      // for (let i = 0; i < e.dataTransfer.files.length; i++) {
      //   const file = e.dataTransfer.files[i];
      //   this.documents.push(file);
      // }
      this.documents = e.dataTransfer.files;
      this.dragging = false;
    },
    dragleave() {
      this.dragging = false;
    },
    async refreshDocuments() {
      const response = await this.getProcessDocuments(this.process.id);
      this.theDocs = response;
    },
    removeDocument(index) {
      this.documents = Array.from(this.documents).filter((file, i) => i !== index);
    },
    async uploadFiles() {
      this.toggleLoading(true);

      if (this.validateFieldDocuments() === false) {
        this.toggleLoading(false);
        return;
      }

      try {
        if (this.documents.length > 0) {
          for (let i = 0; i < this.documents.length; i += 1) {
            const doc = this.documents[i];

            const fileData = new FormData();
            fileData.append('arquivo', doc);

            const response = await api.post(`/api/public/partner/processes/${this.process.id}/documents/type/${doc.tipoDocumento}/upload/${doc.descricao}`, fileData, {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
              validateStatus(status) {
                return status < 500;
              },
            });

            if (response.data.code === 'ERROR') {
              this.$toast.error(response.data.message);
              this.toggleLoading(false);
              return;
            }

            await this.verifyDocActions({
              verifyDoc: true,
              identification: this.process.id,
              docType: doc.tipoDocumento,
            });

            this.docs.push(...response.data.data);
          }
          this.documents = [];
        }
        setTimeout(() => {
          this.refreshDocuments();
        }, 1000);
      } catch (e) {
        console.log(e);
      }
      this.toggleLoading(false);
    },
    async getFileTypes() {
      try {
        const response = await api.get(`/api/public/partner/processes/${this.process.id}/documents/types`);
        this.documentTypes = response.data.data;
      } catch (err) {
        console.log(err);
      }
    },
    validateFieldDocuments() {
      if (this.documents.length > 0) {
        for (let i = 0; i < this.documents.length; i += 1) {
          const doc = this.documents[i];

          if (!doc.descricao) {
            this.$toast.error('Favor informar uma descrição para o documento');
            return false;
          }

          if (!doc.tipoDocumento) {
            this.$toast.error('Favor informar o tipo do documento');
            return false;
          }
        }
        return true;
      }
      return false;
    },
  },
  props: {
    process: {
      type: Object,
      default: () => {
      },
    },
    activeTab: {
      type: Number,
      default: 0,
    },
    docs: {
      type: Array,
      default: () => [],
    },
    disableClick: {
      type: Boolean,
      required: true,
    },
  },
};
</script>

<style scoped>
.search-docs-input {
  min-width: 400px;
}

.search-docs-container button {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  height: 35px;
  padding: 0 10px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.upload-placeholder {
  border: var(--medium-border-width) dashed var(--primary-color);
  border-radius: var(--medium-border-radius);
  padding: 1rem;
}

.upload-placeholder.active {
  background-color: rgba(16, 67, 117, 0.7);
}

.text-right {
  text-align: right !important
}

table:not(.mx-table) tr td {
  min-width: unset !important;
}

select {
  background-color: var(--white-color);
}

</style>
