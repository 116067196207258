<template>
  <svg :width="size" :height="size" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.66667 15.1667H12.8333V23.3333C12.8333 23.6428 12.9562 23.9395 13.175 24.1583C13.3938 24.3771 13.6906 24.5 14 24.5C14.3094 24.5 14.6062 24.3771 14.825 24.1583C15.0437 23.9395 15.1667 23.6428 15.1667 23.3333V15.1667H23.3333C23.6428 15.1667 23.9395 15.0437 24.1583 14.825C24.3771 14.6062 24.5 14.3094 24.5 14C24.5 13.6906 24.3771 13.3938 24.1583 13.175C23.9395 12.9562 23.6428 12.8333 23.3333 12.8333H15.1667V4.66667C15.1667 4.35725 15.0437 4.0605 14.825 3.84171C14.6062 3.62292 14.3094 3.5 14 3.5C13.6906 3.5 13.3938 3.62292 13.175 3.84171C12.9562 4.0605 12.8333 4.35725 12.8333 4.66667V12.8333H4.66667C4.35725 12.8333 4.0605 12.9562 3.84171 13.175C3.62292 13.3938 3.5 13.6906 3.5 14C3.5 14.3094 3.62292 14.6062 3.84171 14.825C4.0605 15.0437 4.35725 15.1667 4.66667 15.1667Z" fill="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: 'PlusIcon',
  props: {
    size: {
      type: String,
      default: '1.5rem',
    },
  },
};
</script>
