<template>
  <svg :width="size" :height="size" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M24 7H22V6C22 5.73478 21.8946 5.48043 21.7071 5.29289C21.5196 5.10536 21.2652 5 21 5C20.7348 5 20.4804 5.10536 20.2929 5.29289C20.1054 5.48043 20 5.73478 20 6V7H17V6C17 5.73478 16.8946 5.48043 16.7071 5.29289C16.5196 5.10536 16.2652 5 16 5C15.7348 5 15.4804 5.10536 15.2929 5.29289C15.1054 5.48043 15 5.73478 15 6V7H12V6C12 5.73478 11.8946 5.48043 11.7071 5.29289C11.5196 5.10536 11.2652 5 11 5C10.7348 5 10.4804 5.10536 10.2929 5.29289C10.1054 5.48043 10 5.73478 10 6V7H8C7.20435 7 6.44129 7.31607 5.87868 7.87868C5.31607 8.44129 5 9.20435 5 10V24C5 24.7956 5.31607 25.5587 5.87868 26.1213C6.44129 26.6839 7.20435 27 8 27H24C24.7956 27 25.5587 26.6839 26.1213 26.1213C26.6839 25.5587 27 24.7956 27 24V10C27 9.20435 26.6839 8.44129 26.1213 7.87868C25.5587 7.31607 24.7956 7 24 7ZM7 10C7 9.73478 7.10536 9.48043 7.29289 9.29289C7.48043 9.10536 7.73478 9 8 9H10V10C10 10.2652 10.1054 10.5196 10.2929 10.7071C10.4804 10.8946 10.7348 11 11 11C11.2652 11 11.5196 10.8946 11.7071 10.7071C11.8946 10.5196 12 10.2652 12 10V9H15V10C15 10.2652 15.1054 10.5196 15.2929 10.7071C15.4804 10.8946 15.7348 11 16 11C16.2652 11 16.5196 10.8946 16.7071 10.7071C16.8946 10.5196 17 10.2652 17 10V9H20V10C20 10.2652 20.1054 10.5196 20.2929 10.7071C20.4804 10.8946 20.7348 11 21 11C21.2652 11 21.5196 10.8946 21.7071 10.7071C21.8946 10.5196 22 10.2652 22 10V9H24C24.2652 9 24.5196 9.10536 24.7071 9.29289C24.8946 9.48043 25 9.73478 25 10V13H7V10ZM25 24C25 24.2652 24.8946 24.5196 24.7071 24.7071C24.5196 24.8946 24.2652 25 24 25H8C7.73478 25 7.48043 24.8946 7.29289 24.7071C7.10536 24.5196 7 24.2652 7 24V15H25V24Z" fill="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: 'CalendarIcon',
  props: {
    size: {
      type: String,
      default: '1.5rem',
    },
  },
};
</script>
