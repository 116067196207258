<template>
  <svg :width="size" :height="size" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M14.9999 3.9C16.1287 3.9 17.2464 4.12254 18.2891 4.55488C19.3318 4.98722 20.2791 5.62089 21.0766 6.41964C21.8742 7.21839 22.5065 8.16654 22.9373 9.20987C23.3682 10.2532 23.5891 11.3712 23.5874 12.5V22.3875H6.41242V12.5C6.41078 11.3712 6.63169 10.2532 7.06252 9.20987C7.49334 8.16654 8.12564 7.21839 8.92322 6.41964C9.72081 5.62089 10.668 4.98722 11.7107 4.55488C12.7534 4.12254 13.8711 3.9 14.9999 3.9V3.9Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M4.21252 22.3875H25.7876" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M19.0878 22.3875C19.0669 22.9025 18.9445 23.4084 18.7275 23.8759C18.5105 24.3435 18.2033 24.7636 17.8234 25.1121C17.4436 25.4605 16.9987 25.7305 16.5142 25.9065C16.0297 26.0825 15.5152 26.161 15.0002 26.1375C14.4853 26.161 13.9708 26.0825 13.4863 25.9065C13.0018 25.7305 12.5568 25.4605 12.177 25.1121C11.7972 24.7636 11.4899 24.3435 11.273 23.8759C11.056 23.4084 10.9335 22.9025 10.9127 22.3875" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'BellIcon',
  props: {
    size: {
      type: String,
      default: '1.5rem',
    },
  },
};
</script>
