<template>
  <div class="full-height flex quote-container">
    <div
      :class="[quoteData.modal === 'RODOVIARIA' ? 'bg-quotation-terrestre' : 'bg-quotation']"
      class="text-center pv-4 ph-5"
    >
      <img
        alt="Prime Internacional"
        v-if="quoteData.typeOfProcess !== 'ENCOMENDA'"
        class="mb-2 mt-1"
        src="@/assets/img/logo-completa.png"
        width="150"
      />
      <img
        alt="Sowport Importação"
        v-else
        class="mb-2 mt-1"
        src="https://s3.sa-east-1.amazonaws.com/public.sowport.com.br/logo/logo-cinza-horizontal.png"
        width="170"
      />

      <h1 class="text-white mb-3 mt-3">
        <b class="block">PORTAL DOS PARCEIROS</b>
        <div class="mt-1">COTAÇÃO DE FRETE</div>
      </h1>

      <p class="text-white mb-2">
        <b>Olá {{ quoteData.pessoa }},</b>
        você está aqui porque clicou no link do email de Pedido de Cotação do nosso Processo de
        Importação <b>{{ quoteData.referenciaNova }}</b
        >, enviado no dia <b>{{ quoteData.envio | moment('DD/MM/YYYY') }}</b> e foi redicionado a
        esta página, para preencher e nos enviar a esta cotação.
      </p>
    </div>

    <div v-if="quoteExpired == false" class="quotation-form full-height overflow pv-4 ph-4">
      <h1 class="text-center text-xg text-bold mb-1">Cotação de Frete Internacional</h1>
      <p class="text-center mb-3">
        Preencha todos os campos com asterisco (*) e faça upload do PDF da Cotação de Frete
      </p>

      <form class="columns gap2 ai-gs" @submit.prevent="saveShippingQuote">
        <div class="span-6">
          <Input
            v-model="quoteData.agente"
            :disabled="true"
            :error="validationErrors.find((err) => err.path === 'agente')"
            className="input-quotation"
            label="Forwarder"
            name="forwarder"
            type="text"
          />
        </div>

        <div class="span-6">
          <Input
            v-model="quoteData.localEmbarque"
            :disabled="true"
            :error="validationErrors.find((err) => err.path === 'localEmbarque')"
            className="input-quotation"
            label="Local do Embarque"
            name="embarque"
            type="text"
          />
        </div>

        <div class="span-6">
          <Input
            v-model="quoteData.portoDestino"
            :disabled="true"
            :error="validationErrors.find((err) => err.path === 'portoDestino')"
            className="input-quotation"
            label="Porto de Destino"
            name="destino"
            type="text"
          />
        </div>

        <div class="span-6 columns gap2">
          <div class="span-6">
            <Input
              v-model="quoteData.incoterm"
              :disabled="true"
              :error="validationErrors.find((err) => err.path === 'incoterm')"
              :required="true"
              className="input-quotation"
              label="Incoterm"
              name="incoterm"
              type="text"
            />
          </div>

          <div class="span-6">
            <Input
              v-model="quoteData.modal"
              :disabled="true"
              :error="validationErrors.find((err) => err.path === 'modal')"
              className="input-quotation"
              label="Modal"
              name="modal"
              type="text"
            />
          </div>
        </div>

        <div class="span-12">
          <label class="flex ai-c jc-sb" for="detalhesCotacao">Detalhes da Cotação</label>
          <textarea
            id="detalhesCotacao"
            v-model="quoteData.detalhes"
            class="input-quotation"
            disabled
            name="detalhesCotacao"
          ></textarea>
        </div>

        <div class="separator span-12"></div>

        <div class="span-12 columns gap2">
          <div class="span-6">
            <label>Valor Total em Dolar $</label>
            <Money
              v-model="quoteData.valorTotalDolar"
              class="input-quotation text-bold text-black"
              v-bind="{
                decimal: ',',
                thousands: '.',
                masked: false,
              }"
            ></Money>
            <div v-if="validationErrors.find((err) => err.path === 'agente')" class="field-error">
              {{ validationErrors.find((err) => err.path === 'valorTotalDolar').message }}
            </div>
          </div>
          <div class="span-3">
            <Input
              v-model="quoteData.freetime"
              :error="validationErrors.find((err) => err.path === 'freetime')"
              :mask="['####']"
              :required="true"
              className="input-quotation text-bold text-black"
              label="Freetime (Dias)*"
              name="freetime"
              type="number"
            />
          </div>
          <div class="span-3">
            <Input
              v-model="quoteData.transittime"
              :error="validationErrors.find((err) => err.path === 'transittime')"
              :mask="['####']"
              :required="true"
              className="input-quotation text-bold text-black"
              label="Transit Time (Dias)*"
              name="transittime"
              type="number"
            />
          </div>
        </div>

        <div class="span-12 columns gap2">
          <div class="span-6">
            <label>Valor Total em Euro €</label>
            <Money
              v-model="quoteData.valorTotalEuro"
              class="input-quotation text-bold text-black"
              v-bind="{
                decimal: ',',
                thousands: '.',
                masked: false,
              }"
            ></Money>
            <div v-if="validationErrors.find((err) => err.path === 'agente')" class="field-error">
              {{ validationErrors.find((err) => err.path === 'valorTotalEuro').message }}
            </div>
          </div>
          <div class="span-6 flex ai-fe">
            <label
              :class="['transbordo flex ai-c text-thin', quoteData.transbordo ? 'active' : '']"
              for="transbordo"
              style="margin-bottom: 0px"
            >
              <input
                id="transbordo"
                v-model="quoteData.transbordo"
                name="transbordo"
                type="checkbox"
              />
              {{ quoteData.transbordo ? 'COM TRANSBORDO' : 'SEM TRANSBORDO' }}
            </label>
          </div>
        </div>

        <div class="span-12 columns gap2">
          <div class="span-6">
            <label>Valor Total em Reais R$</label>
            <Money
              v-model="quoteData.valorTotalReais"
              class="input-quotation text-bold text-black"
              v-bind="{
                decimal: ',',
                thousands: '.',
                masked: false,
              }"
            ></Money>
            <div v-if="validationErrors.find((err) => err.path === 'agente')" class="field-error">
              {{ validationErrors.find((err) => err.path === 'valorTotalReais').message }}
            </div>
          </div>
        </div>

        <div class="span-12 columns gap2">
          <div class="span-6">
            <label>Valor Total em Outra Moeda</label>
            <Money
              v-model="quoteData.valorTotalOutros"
              class="input-quotation text-bold text-black"
              v-bind="{
                decimal: ',',
                thousands: '.',
                masked: false,
              }"
            ></Money>
          </div>
          <div class="span-3">
<!--              <Input-->
<!--                v-model="quoteData.valorTotalOutrosMoeda"-->
<!--                :required="false"-->
<!--                className="input-quotation text-bold text-black"-->
<!--                label="Nome da Moeda"-->
<!--                name="valorTotalOutrosMoeda"-->
<!--                onlyChars-->
<!--                type="text"-->
<!--              />-->
              <label class="flex ai-c jc-sb">
                Moeda
              </label>
              <select v-model="quoteData.moeda" @change="getCurrencyExchangeRate">
                <option value="">Selecione uma opção</option>
                <option v-for="currency in currencies" :value="currency">
                  {{ currency.name }}
                </option>
              </select>
          </div>

          <div class="span-3">
            <label>Taxa da Moeda</label>
            <Money
              v-model="taxaMoeda"
              class="input-quotation text-bold text-black"
              disabled
              v-bind="{
                decimal: ',',
                thousands: '.',
                masked: false,
                precision: 5,
              }"
            ></Money>
          </div>
        </div>

        <div class="span-12">
          <label class="flex ai-c jc-sb" for="observacoesCotacao">Observações da Cotação</label>
          <textarea
            id="observacoesCotacao"
            v-model="quoteData.observacao"
            className="input-quotation text-bold text-black"
            name="observacoesCotacao"
          ></textarea>
        </div>

        <div class="span-12">
          <label
            :class="['upload-placeholder flex fd-c ai-c text-center', dragging && 'active']"
            @dragover.prevent="dragover"
            @dragleave.prevent="dragleave"
            @drop.prevent="drop"
          >
            <CloudIcon class="text-primary mb-1" size="4rem" />
            <div class="pointer">
              <p class="text-uppercase text-primary">
                Clique aqui ou arraste aqui para dentro o PDF da Cotação do Frete
              </p>
            </div>
            <input id="files" class="hide" multiple name="files" type="file" @change="fileChange" />
          </label>

          <div v-if="documents.length > 0" class="table-overflow">
            <div
              v-for="(document, index) in documents"
              :key="index"
              class="flex ai-c fgap2 mb-1 pv-1"
            >
              <span class="text-bold">{{ document.name }}</span>
              <a class="text-danger" href="#" @click.prevent="documents.splice(index, 1)"> X </a>
            </div>
          </div>

          <div v-if="quoteData.documentos.length > 0" class="table-overflow">
            <div
              v-for="(documentSent, index) in quoteData.documentos"
              :key="`document-sent-${index}`"
              class="flex ai-c fgap2 mb-1 pv-1"
            >
              <span class="text-bold">{{ documentSent.description }}</span>
              <small>(Enviado em {{ documentSent.created | moment('DD/MM/YYYY') }} )</small>
            </div>
          </div>
        </div>

        <div class="span-12">
          <label class="flex ai-fs fgap1 text-thin text-md">
            <input
              id="aceite"
              v-model="quoteData.aceite"
              name="aceite"
              style="flex: 0 0 1.5rem; height: 1.5rem"
              type="checkbox"
            />
            <p style="margin-top: 5px">
              Declaro, que tenho permissão para enviar cotações aos clientes, que as informações
              acima são verdadeiras e que assumo inteira responsabilidade pelos dados informados.
            </p>
          </label>
        </div>

        <div class="span-12">
          <button
            :disabled="!quoteData.aceite"
            class="btn solid extra-big text-normal primary full-width"
          >
            ENVIAR COTAÇÃO
          </button>
        </div>
      </form>
    </div>

    <div v-else class="quotation-form full-height flex fd-c jc-c overflow pv-4 ph-4">
      <div class="flex fd-c ai-c jc-c">
        <NoDataIcon size="240px" />

        <h1 class="mt-4 mb-2 text-bold text-center">Cotação de Frete Internacional</h1>

        <p class="text-center">Esta cotação não esta mais disponível para alterações!</p>
      </div>
    </div>

    <Modal :handler="modalOpen" :useActions="true" @request-close="modalOpen = false">
      <template #content>
        <h3 class="text-center text-bold mb-2">Cotação de Frete Internacional</h3>
        <p class="text-center">{{ modalMessage }}</p>
      </template>

      <template #cancel-button>
        <button class="btn solid primary text-uppercase" @click.prevent="modalOpen = false">
          Clique aqui para continuar
        </button>
      </template>
    </Modal>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { Money } from 'v-money';
// eslint-disable-next-line import/no-cycle
import * as yup from 'yup';
// eslint-disable-next-line import/no-cycle
import api from '@/services/api';
import Input from '@/components/DataInput/Input.vue';
import Modal from '@/components/Modal.vue';

import { CloudIcon, NoDataIcon } from '@/components/Icons';
import ca from 'vue2-datepicker/locale/es/ca';
import { string } from 'yup/lib/locale';

export default {
  name: 'ShipQuotation',
  components: {
    NoDataIcon,
    CloudIcon,
    Input,
    Money,
    Modal,
  },
  data() {
    return {
      quoteData: {
        moeda: {},
        aceite: false,
        documentos: [],
      },
      taxaMoeda: 0,
      documents: [],
      dragging: false,
      modalOpen: false,
      modalMessage: false,
      modalWarnOpen: false,
      modalWarnMessage: false,
      validationErrors: [],
      quoteExpired: true,
      currencies: [],
      exchangeRates: [],
    };
  },
  async created() {
    this.toggleLoading(true);
    const codigoCotacao = this.$route.params.codigo;

    if (codigoCotacao) {
      try {
        const response = await api.get(`/freightQuotation/retrieve/${codigoCotacao}`);

        if (response) {
          this.quoteData = response.data.data;

          if (this.quoteData.approved !== null) {
            throw new Error('Esta cotação não esta mais disponível para alterações!');
          } else if (this.quoteData.preenchimento !== null) {
            this.modalOpen = true;
            this.modalMessage = response.data.message;
            this.quoteExpired = false;
          } else {
            this.quoteData.valorTotalDolar = 0;
            this.quoteData.valorTotalEuro = 0;
            this.quoteData.valorTotalOutros = 0;
            this.quoteData.valorTotalReais = 0;
            this.quoteExpired = false;
          }
        } else {
          throw new Error('Não foi possível recuperar a cotação!');
        }
      } catch (err) {
        this.quoteExpired = true;
      }
    }

    this.toggleLoading(false);
  },
  async mounted() {
    await this.getCurrencies();
  },
  methods: {
    ...mapActions(['toggleLoading']),
    dragover() {
      this.dragging = true;
    },
    drop(e) {
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < e.dataTransfer.files.length; i++) {
        const file = e.dataTransfer.files[i];
        this.documents.push(file);
      }
      this.dragging = false;
    },
    fileChange(e) {
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < e.target.files.length; i++) {
        const file = e.target.files[i];
        this.documents.push(file);
      }
    },
    dragleave() {
      this.dragging = false;
    },
    async getCurrencies() {
      try {
        const response = await api.get('/currencies');
        console.log(response.data.data);
        this.currencies = response.data.data.currencies;
        this.exchangeRates = response.data.data.exchageRates;
      } catch (e) {
        console.error(e);
      }
    },
    async getCurrencyExchangeRate() {
      console.log(this.quoteData.moeda);
      console.log(this.exchangeRates.filter((ex) => ex.code.toString() === this.quoteData.moeda.code));
      this.taxaMoeda = this.exchangeRates.filter((ex) => ex.code.toString() === this.quoteData.moeda.code)[0]?.currentBuyValue ?? 0;
    },
    async uploadFiles() {
      try {
        if (this.documents.length > 0) {
          const fileDataImg = new FormData();
          this.documents.map((doc) => fileDataImg.append('arquivo', doc));
          const response = await api.post(
            `/freightQuotation/saveShippingQuoteDocument/${this.quoteData.codigo}`,
            fileDataImg,
            {
              headers: {
                Authorization: localStorage.getItem('@GSCMX:access_token'),
                'Content-Type': 'multipart/form-data',
              },
            },
          );
          if (response.data.code === 'ERROR') {
            this.modalOpen = true;
            this.modalMessage = response.data.message;
          }
        }
      } catch (e) {
        this.modalOpen = true;
        this.modalMessage = `Houve algum problema ao tentar enviar o documento: <br />${e}`;
      }
    },
    async saveShippingQuote() {
      try {
        console.log(this.quoteData);
        const schema = yup.object().shape({
          valorTotalDolar:
            this.quoteData.modal !== 'RODOVIARIA'
              ? yup.string().required('Valor total em Dólar é obrigatório!')
              : null,
          valorTotalEuro:
            this.quoteData.modal !== 'RODOVIARIA'
              ? yup.string().required('Valor total em Euro é obrigatório!')
              : null,
          valorTotalReais:
            this.quoteData.modal !== 'RODOVIARIA'
              ? yup.string().required('Valor total em Reais é obrigatório!')
              : null,
          freetime:
            this.quoteData.modal !== 'RODOVIARIA'
              ? yup.string().required('Freetime é obrigatório!')
              : null,
          transittime:
            this.quoteData.modal !== 'RODOVIARIA'
              ? yup.string().required('Transit Time é obrigatório!')
              : null,
          agente: yup.string().required('Forwarder é obrigatório!'),
          localEmbarque: yup.string().required('Local do Embarque é obrigatório!'),
          portoDestino: yup.string().required('Porto Destino é obrigatório!'),
          incoterm: yup.string().required('Incoterm é obrigatório!'),
          modal: yup.string().required('Modal é obrigatório!'),
          // freightTotalValue:
          //   this.quoteData.modal === 'RODOVIARIA'
          //     ? yup.string().required('O valor de frete é obrigatório!')
          //     : null,
          // adValoremPercentage:
          //   this.quoteData.modal === 'RODOVIARIA'
          //     ? yup.string().required('A porcentagem do Ad Valorem é obrigatória!')
          //     : null,
        });

        await schema.validate(this.quoteData, {
          abortEarly: false,
        });

        if (this.documents.length === 0) {
          this.modalOpen = true;
          this.modalMessage = 'Nenhum documento foi enviado!';
        } else {
          this.toggleLoading(true);
          const response = await api.post(
            `/freightQuotation/saveShippingQuote/${this.quoteData.codigo}`,
            {
              ...this.quoteData,
              freetime: parseInt(this.quoteData.freetime),
              transittime: parseInt(this.quoteData.transittime),
            },
          );
          if (response.data.code === 'SUCCESS') {
            await this.uploadFiles();
            this.modalOpen = true;
            this.modalMessage = 'Cotação de Frete enviada com sucesso!';
          } else {
            this.modalOpen = true;
            this.modalMessage = response.data.message;
          }
          this.toggleLoading(false);
        }
      } catch (e) {
        console.log(this.validationErrors);
        if (e.inner) {
          this.validationErrors = e.inner;
        } else {
          this.modalOpen = true;
          this.modalMessage = e;
        }
        this.toggleLoading(false);
      }
    },
  },
};
</script>

<style scoped>
.bg-quotation-terrestre {
  flex: 0 0 35%;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    url('../../assets/img/bg-cotacao-terrestre.jpg') center bottom / cover no-repeat;
}

.bg-quotation {
  flex: 0 0 35%;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    url('../../assets/img/bg-cotacao.jpg') center bottom / cover no-repeat;
}

.quotation-form {
  flex: 0 0 65%;
}

.separator {
  width: 100%;
  height: 2px;
  background-color: var(--input-border-color);
}

.quotation-form {
  background-color: var(--white-color);
}

textarea {
  border-radius: var(--medium-border-radius);
  color: var(--black-color);
}

button:disabled {
  background-color: var(--light-bg-color) !important;
  color: var(--light-text-color) !important;
  border: 1px solid var(--light-bg-color) !important;
}

.transbordo {
  background-color: #46a035;
  color: var(--white-color);
  padding: 0.2rem 0.5rem 0.2rem 0.2rem;
  border-radius: var(--medium-border-radius);
  font-size: 1rem;
}

.transbordo.active {
  background-color: #e03434;
}

.transbordo input {
  border-radius: 50%;
  border: 0;
  margin-right: 10px;
}

.transbordo input:checked {
  background: radial-gradient(#000 0%, #000 30%, #fff 30%, #fff 100%);
}

@media screen and (max-width: 1020px) {
  .quote-container {
    flex-direction: column;
  }

  .bg-quotation,
  .bg-quotation-terrestre,
  .quotation-form {
    flex: 0 1 auto;
  }

  .bg-quotation {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
      url('../../assets/img/bg-cotacao.jpg') center bottom / cover no-repeat;
  }

  .bg-quotation-terrestre {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
      url('../../assets/img/bg-cotacao-terrestre.jpg') center bottom / cover no-repeat;
  }
}

.upload-placeholder {
  border: var(--medium-border-width) dashed var(--primary-color);
  border-radius: var(--medium-border-radius);
  padding: 1rem;
}

.upload-placeholder.active {
  background-color: rgba(16, 67, 117, 0.7);
}
</style>
