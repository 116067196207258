import DialogComponent from '@/components/DialogComponent.vue';

const Modal = {
  install(Vue) {
    this.EventBus = new Vue();

    Vue.component('dialog-component', DialogComponent);

    Vue.prototype.$modal = {
      show(params) {
        Modal.EventBus.$emit('show', params);
      },
      success() {
        Modal.EventBus.$emit('dialogType', 'success');
        return this;
      },
      info() {
        Modal.EventBus.$emit('dialogType', 'info');
        return this;
      },
      danger() {
        Modal.EventBus.$emit('dialogType', 'danger');
        return this;
      },
    };
  },
};

export default Modal;

// How To Use Dialog/Modal

// this.$modal.show({
//   title: 'teste',
//   text: 'teste',
//   onConfirm: () => {
//     Your Method
//   },
// });
