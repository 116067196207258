<template>
  <div v-if="activeTab == 1">
    <div class="columns gap2">
      <div class="span-12 columns">

        <div class="span-12 medium-border-radius border-form gap2 mb-1 ph-2 pb-2 pt-2">

          <div class="flex mt-1 mb-2 fd-c">
            <h5 class="text-bold mb-1 text-primary"> INFORMAÇÕES GERAIS</h5>
            <span class="title-border-bottom"></span>
          </div>

          <div class="gap1 columns">

            <div class="span-4 mb-1">
              <div class="flex ai-c jc-sb mb-1">
                <div class="flex ai-c">
                  Status do Parceiro
                </div>
              </div>
              <input type="text" class="mb-05 input-status-custom-broker" v-model="process.statusCustomBroker" :disabled="disableClick" placeholder='' @input="() => inputUpdate('statusCustomBroker')" maxlength="50">
              <small> Já foi digitado {{ process.statusCustomBroker.length}} de um total 50 caracteres permitidos.</small>
            </div>

            <div class="span-2">
              <div class="flex ai-c jc-sb mb-1">
                <div class="flex ai-c"> Referência do Parceiro </div>
              </div>
              <input v-model="process.customBrokerRef" :disabled="disableClick" type="text" @input="() => inputUpdate('customBrokerRef')"/>
            </div>

            <div class="span-2">
              <div class="flex ai-c jc-sb mb-1">
                <div class="flex ai-c"> Navio de Embarque </div>
              </div>
              <input v-model="process.vehicleTranshipment" :disabled="disableClick" type="text" @input="() => inputUpdate('vehicleTranshipment')"/>
            </div>

            <div class="span-2 mb-1">
              <div class="flex ai-c jc-sb mb-1">
                <div class="flex ai-c">
                  Nº CE Mercante
                </div>
              </div>
              <input v-model="process.ceMercante" :disabled="disableClick" type="text" @input="() => inputUpdate('ceMercante')"/>
            </div>

          </div>

          <hr class="mb-2 mt-0 hr-tab-general"/>

          <div class="gap1 columns">

            <div class="span-2 mb-1">
              <div class="flex ai-c jc-sb mb-1">
                <div class="flex ai-c text-light">
                  Cliente
                </div>
              </div>
              <span class="result-span">{{process.customer.name ? process.customer.name : 'N/I'}}</span>
            </div>

            <div class="span-2 mb-1">
              <div class="flex ai-c jc-sb mb-1">
                <div class="flex ai-c text-light">
                  Ref. Pedido / Cliente
                </div>
              </div>
              <span class="result-span">{{process.customerRef ? process.customerRef : 'N/I'}}</span>
            </div>

            <div class="span-2 mb-1">
              <div class="flex ai-c jc-sb mb-1">
                <div class="flex ai-c text-light">
                  Exportador
                </div>
              </div>
              <span class="result-span">{{process.exporter.name ? process.exporter.name : 'N/I'}}</span>
            </div>

            <div class="span-2 mb-1">
              <div class="flex ai-c jc-sb mb-1">
                <div class="flex ai-c text-light">
                  Fabricante / Produtor
                </div>
              </div>
              <span class="result-span">{{process.manufacturer.name ? process.manufacturer.name : 'N/I'}}</span>
            </div>

            <div class="span-2 mb-1">
              <div class="flex ai-c jc-sb mb-1">
                <div class="flex ai-c text-light">
                  Invoice
                </div>
              </div>
              <span class="result-span">{{process.invoice ? process.invoice : 'N/I'}}</span>
            </div>

            <div class="span-2 mb-1">
              <div class="flex ai-c jc-sb mb-1">
                <div class="flex ai-c text-light">
                  <CalendarAltIcon class="mr-1"/> Data Invoice
                </div>
              </div>
              <span class="result-span">{{process.invoiceDate ? formatData(process.invoiceDate) : 'N/I'}}</span>
            </div>

            <div class="span-2 mb-1">
              <div class="flex ai-c jc-sb mb-1">
                <div class="flex ai-c text-light">
                  Local de Embarque
                </div>
              </div>
              <span class="result-span">{{process.placeOfLoading ? process.placeOfLoading : 'N/I'}}</span>
            </div>

            <div class="span-2 mb-1">
              <div class="flex ai-c jc-sb mb-1">
                <div class="flex ai-c text-light">
                  Outras Referências
                </div>
              </div>
              <span class="result-span">{{process.anotherRef ? process.anotherRef : 'N/I'}}</span>
            </div>

            <div class="span-2 mb-1">
              <div class="flex ai-c jc-sb mb-1">
                <div class="flex ai-c text-light">
                  Seguro Internacional
                </div>
              </div>
              <span class="result-span">{{process.insuranceAward ? process.insuranceAward : 'N/I'}}</span>
            </div>
          </div>
        </div>

        <div class="span-12 medium-border-radius border-form gap2 mb-1 ph-2 pb-2 pt-2" style="background: #fbfbfb;">
          <div class="flex mt-1 mb-2 fd-c">
            <h5 class="text-bold  mb-1 text-primary"> DATAS PREVISTAS </h5>
            <span class="title-border-bottom"></span>
          </div>
          <div class="gap2 columns">

            <div class="span-2">
              <div class="flex ai-c jc-sb mb-1">
                <div class="flex ai-c text-light">
                  <CalendarAltIcon class="mr-1"/> Prontidão de Carga
                </div>
              </div>
              <span class="result-span">{{process.datesEstimatedGoodsReadinesDate ? formatData(process.datesEstimatedGoodsReadinesDate) : 'N/I'}}</span>
            </div>

            <div class="span-2">
              <div class="flex ai-c jc-sb mb-1">
                <div class="flex ai-c text-light">
                  <CalendarAltIcon class="mr-1"/> ETD Inicial
                </div>
              </div>
              <span class="result-span">{{process.initialDatesETD ? formatData(process.initialDatesETD) : 'N/I'}}</span>
            </div>

            <div class="span-2">
              <div class="flex ai-c jc-sb mb-1">
                <div class="flex ai-c text-light">
                  <CalendarAltIcon class="mr-1"/> ETD
                </div>
              </div>
              <span class="result-span">{{process.datesETD ? formatData(process.datesETD) : 'N/I'}}</span>
            </div>

            <div class="span-2">
              <div class="flex ai-c jc-sb mb-1">
                <div class="flex ai-c text-light">
                  <CalendarAltIcon class="mr-1"/> ETA
                </div>
              </div>
              <span class="result-span">{{process.datesETA ? formatData(process.datesETA) : 'N/I'}}</span>
            </div>

            <div class="span-2">
              <div class="flex ai-c jc-sb mb-1">
                <div class="flex ai-c text-light">
                  <CalendarAltIcon class="mr-1"/> Numerário
                </div>
              </div>
              <span class="result-span">{{process.estimatedStepCashRequest ? formatData(process.estimatedStepCashRequest) : 'N/I'}}</span>
            </div>

            <div class="span-2">
              <div class="flex ai-c jc-sb mb-1">
                <div class="flex ai-c text-light">
                  <CalendarAltIcon class="mr-1"/> Entrega no Cliente
                </div>
              </div>
              <span class="result-span">{{process.datesEstimatedCustomerDeliverDate ? formatData(process.datesEstimatedCustomerDeliverDate) : 'N/I'}}</span>
            </div>
          </div>
        </div>

        <div class="span-12 medium-border-radius border-form gap2 mb-1 ph-2 pb-2 pt-2">
          <div class="flex mt-1 mb-2 fd-c">
            <h5 class="text-bold mb-1 text-primary"> DATAS CONFIRMADAS </h5>
            <span class="title-border-bottom"></span>
          </div>

          <div class="gap1 columns">
            <div class="span-2">
              <div class="flex ai-c jc-sb mb-1">
                <div class="flex ai-c text-light">
                  <CalendarAltIcon class="mr-1"/> Prontidão de Carga
                </div>
              </div>
              <span class="result-span">{{process.datesGoodsReadinesDate ? formatData(process.datesGoodsReadinesDate) : 'N/I'}}</span>
            </div>

            <div class="span-2">
              <div class="flex ai-c jc-sb mb-1">
                <div class="flex ai-c text-light">
                  <CalendarAltIcon class="mr-1"/> Embarque
                </div>
              </div>
              <span class="result-span">{{process.datesDepartureDate ? formatData(process.datesDepartureDate) : 'N/I'}}</span>
            </div>

            <div class="span-2">
              <div class="flex ai-c jc-sb mb-1">
                <div class="flex ai-c text-light">
                  <CalendarAltIcon class="mr-1"/> Atracação
                </div>
              </div>
              <span class="result-span">{{process.mooringDate ? formatData(process.mooringDate) : 'N/I'}}</span>
            </div>

            <div class="span-2">
              <div class="flex ai-c jc-sb mb-1">
                <div class="flex ai-c text-light">
                  <CalendarAltIcon class="mr-1"/> Chegada
                </div>
              </div>
              <span class="result-span">{{process.datesArrivalDate ? formatData(process.datesArrivalDate) : 'N/I'}}</span>
            </div>

            <div class="span-2">
              <div class="flex ai-c jc-sb mb-1">
                <div class="flex ai-c text-light">
                  <CalendarAltIcon class="mr-1"/> Carregamento
                </div>
              </div>
              <span class="result-span">{{process.datesGoodsLoadingDate ? formatData(process.datesGoodsLoadingDate) : 'N/I'}}</span>
            </div>

            <div class="span-2">
              <div class="flex ai-c jc-sb mb-1">
                <div class="flex ai-c text-light">
                  <CalendarAltIcon class="mr-1"/> Entrega no Cliente
                </div>
              </div>
              <span class="result-span">{{process.datesCustomerDeliverDate ? formatData(process.datesCustomerDeliverDate) : 'N/I'}}</span>
            </div>
          </div>

          <hr class="mb-2 mt-2 hr-tab-general"/>

          <div class="gap1 columns">
            <div class="span-2 mb-1">
              <div class="flex ai-c jc-sb mb-1">
                <div class="flex ai-c">
                  <CalendarAltIcon class="mr-1"/> Presença
                </div>
              </div>
              <DatePicker v-model="process.datesGoodsPresenceDate" :disabled="disableClick" placeholder='' @input="() => inputUpdate('datesGoodsPresenceDate')"/>
            </div>

            <div class="span-2 mb-1">
              <div class="flex ai-c jc-sb mb-1">
                <div class="flex ai-c">
                  <CalendarAltIcon class="mr-1"/>  Vistoria RFB
                </div>
              </div>
              <DatePicker v-model="process.datesInspectionOfTheDIDate" :disabled="disableClick" placeholder='' @input="() => inputUpdate('datesInspectionOfTheDIDate')" classInpurt="mb-05"/>
              <small class="flex ai-c" style="gap: 2px;"><InfoIcon size="0.8rem"/><span>Em caso de canal diferente de verde</span></small>
            </div>

            <div class="span-2 mb-1">
              <div class="flex ai-c jc-sb mb-1">
                <div class="flex ai-c ">
                  <CalendarAltIcon class="mr-1"/> Vistoria do MAPA
                </div>
              </div>
              <DatePicker v-model="process.datesInspectionMAPADate" :disabled="disableClick" placeholder='' @input="() => inputUpdate('datesInspectionMAPADate')"/>
            </div>

            <div class="span-2 mb-1">
              <div class="flex ai-c jc-sb mb-1">
                <div class="flex ai-c ">
                  <CalendarAltIcon class="mr-1"/> Liberação do MAPA
                </div>
              </div>
              <DatePicker v-model="process.datesReleaseMAPADate" :disabled="disableClick" placeholder='' @input="() => inputUpdate('datesReleaseMAPADate')"/>
            </div>

            <div class="span-2 mb-1">
              <div class="flex ai-c jc-sb mb-1">
                <div class="flex ai-c ">
                  <CalendarAltIcon class="mr-1"/> Vínculo no Recinto
                </div>
              </div>
              <DatePicker v-model="process.datesLinkInTheEnclosureDate" :disabled="disableClick" placeholder='' @input="() => inputUpdate('datesLinkInTheEnclosureDate')"/>
            </div>

          </div>

        </div>

        <div class="span-6 medium-border-radius border-form gap2 mr-1 mb-1 ph-2 pb-2 pt-2" style="background: #fbfbfb;">
          <div class="flex mt-1 mb-2 fd-c">
            <h5 class="text-bold mb-1 text-primary">  CONHECIMENTO </h5>
            <span class="title-border-bottom"></span>
          </div>
          <div class="gap2 columns">
            <div class="span-3">
              <div class="flex ai-c jc-sb mb-1">
                <div class="flex ai-c ">
                  Situação do Siscarga
                </div>
              </div>
              <Toggle v-model="process.datesSiscargaState" :disabled="disableClick" :labels="{ checked: 'LIBERADO', unchecked: 'PENDENTE', }" name="sit-siscarga" @input="() => inputUpdate('datesSiscargaState')" classLabel="text-medium text-md"/>
            </div>

            <div class="span-3">
              <div class="flex ai-c jc-sb mb-1">
                <div class="flex ai-c ">
                  <CalendarAltIcon class="mr-1"/> Siscarga
                </div>
              </div>
              <DatePicker v-model="process.datesSiscargaDate" :disabled="disableClick" @input="() => inputUpdate('datesSiscargaDate')"/>
            </div>

            <div class="span-3">
              <div class="flex ai-c jc-sb mb-1">
                <div class="flex ai-c ">
                  <CalendarAltIcon class="mr-1"/> Liberação AWB / BL
                </div>
              </div>
              <DatePicker v-model="process.datesBillOfLadingReleaseDate" :disabled="disableClick" @input="() => inputUpdate('datesBillOfLadingReleaseDate')"/>
            </div>

            <div class="span-3">
                <div class="flex ai-c jc-sb mb-1">
                  <div class="flex ai-c ">
                    TFA
                  </div>
                </div>
                <Toggle v-model="process.datesTFASolved" :disabled="disableClick" :labels="{ checked: 'LIBERADO', unchecked: 'PENDENTE', }" name="tfa" @input="() => inputUpdate('datesTFASolved')" classLabel="text-medium text-md"/>
              </div>
            </div>
        </div>

        <div class="span-6 medium-border-radius border-form gap2 mr-1 mb-1 ph-2 pb-2 pt-2" style="background: #fbfbfb;">
          <div class="flex mt-1 mb-2 fd-c">
            <h5 class="text-bold  mb-1 text-primary">  TRANSFERÊNCIA DE CARGA </h5>
            <span class="title-border-bottom"></span>
          </div>
          <div class="gap2 columns">
            <div class="span-4" v-if="process.datesIsThereDTCDTATransfer">
              <div class="flex ai-c jc-sb mb-1">
                <div class="flex ai-c ">
                  <CalendarAltIcon class="mr-1"/> DTC / DTA
                </div>
              </div>
              <DatePicker v-model="process.datesDTCDTA" :disabled="disableClick" placeholder='' @input="() => inputUpdate('datesInspectionOfTheDIDate')"/>
            </div>

            <div class="span-4">
              <div class="flex ai-c jc-sb mb-1">
                <div class="flex ai-c ">
                  <CalendarAltIcon class="mr-1"/> Faturamento do Recinto
                </div>
              </div>
              <DatePicker v-model="process.datesEnclosureBillingDate" :disabled="disableClick" placeholder='' @input="() => inputUpdate('datesEnclosureBillingDate')"/>
            </div>

            <div class="span-4">
              <div class="flex ai-c jc-sb mb-1">
                <div class="flex ai-c ">
                  <CalendarAltIcon class="mr-1"/> Comex
                </div>
              </div>
              <DatePicker v-model="process.datesComexDate" :disabled="disableClick" placeholder='' @input="() => inputUpdate('datesComexDate')"/>
            </div>

            <div class="span-4">
              <div class="flex ai-c jc-sb mb-1">
                <div class="flex ai-c ">
                  Desova
                </div>
              </div>
              <Toggle v-model="process.datesThereIsDesova" :disabled="disableClick" :labels="{ checked: 'SIM', unchecked: 'NÃO', }" name="sit-siscarga" @input="() => inputUpdate('datesThereIsDesova')" classLabel="text-medium text-md"/>
            </div>

            <div class="span-4" v-if="process.datesThereIsDesova">
              <div class="flex ai-c jc-sb mb-1">
                <div class="flex ai-c ">
                  Data de Desova
                </div>
              </div>
              <DatePicker v-model="process.datesDesova" :disabled="disableClick" placeholder='' @input="() => inputUpdate('datesDesova')"/>
            </div>
          </div>
        </div>

        <div class="span-12 medium-border-radius border-form gap2 mb-1 ph-2 pb-2 pt-2" style="grid-row: span 3;">
          <div class="flex mt-1 mb-2 fd-c">
            <h5 class="text-bold mb-1 text-primary">  DECLARAÇÃO DE IMPORTAÇÃO</h5>
            <span class="title-border-bottom"></span>
          </div>
          <div class="gap2 columns">
            <div class="span-2">
              <div class="flex ai-c jc-sb mb-1">
                <div class="flex ai-c ">
                  <CalendarAltIcon class="mr-1"/>
                  Data de Digitação
                </div>
              </div>
              <DatePicker v-model="process.diTypingDate" :disabled="disableClick" placeholder='' @input="() => inputUpdate('diTypingDate')"/>
            </div>

            <div class="span-2">
              <div class="flex ai-c jc-sb mb-1">
                <div class="flex ai-c ">
                  <CalendarAltIcon class="mr-1"/>
                  Data de Registro
                </div>
              </div>
              <DatePicker v-model="process.diRegistryDate" :disabled="disableClick" placeholder='' @input="() => inputUpdate('diRegistryDate')"/>
            </div>

            <div class="span-2">
              <div class="flex ai-c jc-sb mb-1">
                <div class="flex ai-c ">
                  Número da DI
                </div>
              </div>
              <input v-model="process.diNumber" v-mask="'##/#######-##'" :disabled="disableClick" type="text" @click="consultDi" @input="() => inputUpdate('diNumber')"/>
            </div>

            <div class="span-2">
              <div class="flex ai-c jc-sb mb-1">
                <div class="flex ai-c ">
                  Protocolo de Registro da DI
                </div>
              </div>
              <input v-model="process.diProtocol" :disabled="disableClick" type="text" @input="() => inputUpdate('diProtocol')" maxlength="10"/>
            </div>

            <div class="span-2">
              <div class="flex ai-c jc-sb mb-1">
                <div class="flex ai-c ">
                  <CalendarAltIcon class="mr-1"/>Data de Desembaraço
                </div>
              </div>
              <DatePicker v-model="process.diResourcefulnessDate" :disabled="disableClick" placeholder='' @input="() => inputUpdate('diResourcefulnessDate')"/>
            </div>

            <div class="span-2">
              <div class="flex ai-c jc-sb mb-1">
                <div class="flex ai-c" style="gap: 3px">
                  Canal<span v-if="process.diChannel" :class="process.diChannel === 'VERDE' ? 'text-success' : process.diChannel === 'AMARELO' ? 'text-warning' : process.diChannel === 'VERMELHO' ? 'text-danger' : process.diChannel === 'CINZA' ? '' : '' "> <strong>{{ process.diChannel }}</strong> </span>
                </div>
              </div>
              <select v-model="process.diChannel" :disabled="disableClick" class="custom-select form-control wd-100" name="diChannel" @input="() => inputUpdate('diChannel')">
                <option value></option>
                <option value="VERDE">Verde</option>
                <option value="AMARELO">Amarelo</option>
                <option value="VERMELHO">Vermelho</option>
                <option value="CINZA">Cinza</option>
              </select>
            </div>

            <!-- <div class="span-12 mt-1">
              <div class="text-center">
                <DoubleCheckIcon class="mb-1" :class="process.diChannel === 'VERDE' ? 'text-success' : process.diChannel === 'AMARELO' ? 'text-warning' : process.diChannel === 'VERMELHO' ? 'text-danger' : process.diChannel === 'CINZA' ? '' : '' " size="3rem"/>
                <h4 class="text-bold text-center mb-2 text-primary"> DESEMBARAÇADO</h4>
                <p v-if="process.diChannel" :class="process.diChannel === 'VERDE' ? 'text-success' : process.diChannel === 'AMARELO' ? 'text-warning' : process.diChannel === 'VERMELHO' ? 'text-danger' : process.diChannel === 'CINZA' ? '' : '' "> Canal <strong>{{ process.diChannel }}</strong> </p>
                <p v-else>Nenhum canal informado</p>
              </div>
            </div> -->
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { mask } from 'vue-the-mask';
import { VMoney } from 'v-money';
import moment from 'moment';
import api from '@/services/api';

import {
  BuildingIcon,
  InvoiceIcon,
  IdCardIcon,
  CalendarAltIcon,
  InfoIcon,
  DoubleCheckIcon,
  IndustryIcon,
  FileIcon,
  MapPinIcon,
  BoxesIcon,
} from '@/components/Icons';
import Toggle from '@/components/DataInput/Toggle.vue';
import DatePicker from '@/components/DataInput/DatePicker.vue';

export default {
  name: 'ModalProcessTabGeneral',
  directives: {
    mask,
    money: VMoney,
  },
  components: {
    // Icons
    BuildingIcon,
    InvoiceIcon,
    IdCardIcon,
    CalendarAltIcon,
    InfoIcon,
    DoubleCheckIcon,
    IndustryIcon,
    FileIcon,
    MapPinIcon,
    BoxesIcon,
    // Componenets
    Toggle,
    DatePicker,
  },
  props: {
    process: {
      type: Object,
      default: () => {},
    },
    activeTab: {
      type: Number,
      default: 0,
    },
    disableClick: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      changed: false,
      moneyFormat: {
        decimal: ',',
        thousands: '.',
      },
    };
  },
  methods: {
    formatData(data) {
      if (data) {
        return moment(data).format('DD/MM/YYYY');
      }

      return null;
    },
    ...mapActions(['toggleLoading']),
    ...mapActions('process', ['patchProcess']),
    async inputUpdate(field) {
      if (this.changed) {
        const data = {
          identification: this.process.identification,
          fieldUpdated: field,
          data: this.process,
        };
        await this.patchProcess(data);
      }
    },
    async consultDi() {
      if (this.process.diNumber.length === 13) {
        this.toggleLoading(true);
        const response = await api.get(
          `/integrations/myimp/consultarDi?processId=${this.process.id}&diIdentification=${this.process.diNumber}`,
        );

        if (response.data) {
          this.process.diRegistryDate = response.data.registryDate;
          this.process.diResourcefulnessDate = response.data.resourcefulnessDate;

          this.$toast.success('Dados encontrados com sucesso!');
        } else {
          this.$toast.error('Nenhuma informação encontrada');
        }

        this.toggleLoading(false);
      }
    },
  },
  updated() {
    this.changed = true;
  },
};
</script>

<style scoped>
.border-form {
  border: 1px solid #e2e2e2;
}
.title-border-bottom {
  border-bottom: var(--small-border-width) solid var(--input-border-color);
  width: 100%;
}
.hr-tab-general {
  border-color: #f2f2f2;
  border-top-width: 2px;
}
</style>
