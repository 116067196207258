<template>
  <svg :width="size" :height="size" viewBox="0 0 50 41" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M42 18.2031C42.3203 17.3672 42.5 16.4531 42.5 15.5C42.5 11.3594 39.1406 8 35 8C33.4609 8 32.0234 8.46875 30.8359 9.26562C28.6719 5.51562 24.6328 3 20 3C13.0938 3 7.5 8.59375 7.5 15.5C7.5 15.7109 7.50781 15.9219 7.51562 16.1328C3.14062 17.6719 0 21.8438 0 26.75C0 32.9609 5.03906 38 11.25 38H40C45.5234 38 50 33.5234 50 28C50 23.1641 46.5625 19.125 42 18.2031ZM30.7344 23H25.625V31.75C25.625 32.4375 25.0625 33 24.375 33H20.625C19.9375 33 19.375 32.4375 19.375 31.75V23H14.2656C13.1484 23 12.5938 21.6563 13.3828 20.8672L21.6172 12.6328C22.1016 12.1484 22.8984 12.1484 23.3828 12.6328L31.6172 20.8672C32.4062 21.6563 31.8438 23 30.7344 23Z" fill="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: 'CloudIcon',
  props: {
    size: {
      type: String,
      default: '1.5rem',
    },
  },
};
</script>
