<template>
  <svg :width="size" :height="size" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.37417 23.0637C1.58955 23.0637 1.80659 23.0038 1.99945 22.8791L6.84999 19.7418V43.183H3.32802C2.69175 43.183 2.17472 43.6989 2.17472 44.3363C2.17472 44.9736 2.6912 45.4896 3.32802 45.4896H47.3643C48 45.4896 48.5176 44.9736 48.5176 44.3363C48.5176 43.6989 48.0005 43.183 47.3643 43.183H43.2258V19.7401L48.0758 22.878C48.611 23.2253 49.3258 23.0709 49.6703 22.5368C50.0159 22.0016 49.8632 21.2879 49.3291 20.9423L42.7319 16.6742C42.7187 16.6648 42.7049 16.6566 42.6918 16.6478L25.667 5.63187C25.2857 5.38516 24.7945 5.38461 24.4137 5.63132L7.37637 16.6538C7.36593 16.6599 7.35604 16.6659 7.34725 16.6731L0.746698 20.9423C0.212082 21.2885 0.0587857 22.0022 0.40439 22.5368C0.625269 22.8775 0.996148 23.0637 1.37417 23.0637ZM25.0396 7.97308L40.9187 18.2478V43.1819H9.15604V18.2489L25.0396 7.97308Z" fill="currentColor"/>
    <path d="M15.1489 21.9945L18.5917 23.6522C18.6264 23.6692 18.6637 23.6769 18.7011 23.6769C18.7379 23.6769 18.7753 23.6692 18.8093 23.6522L28.5945 18.9522C28.6824 18.9104 28.7373 18.8225 28.7373 18.7258C28.7373 18.6297 28.6824 18.5412 28.5956 18.4994L25.1527 16.8429C25.083 16.8088 25.0033 16.8088 24.9346 16.8429L15.1505 21.5418C15.0626 21.5835 15.0077 21.6709 15.0077 21.7681C15.0071 21.8643 15.0626 21.9533 15.1489 21.9945Z" fill="currentColor"/>
    <path d="M13.506 34.8593L23.6725 39.7538C23.7071 39.7709 23.7445 39.7786 23.7819 39.7786C23.828 39.7786 23.8742 39.7659 23.9148 39.7407C23.9879 39.6945 24.033 39.6143 24.033 39.5275V28.6714C24.033 28.5753 23.9775 28.4868 23.8912 28.4456L13.7247 23.5505C13.6456 23.5121 13.5533 23.5181 13.4819 23.5637C13.4088 23.6099 13.3648 23.6907 13.3648 23.7769V34.633C13.3643 34.7297 13.4192 34.8176 13.506 34.8593ZM21.3033 31.2451C21.0714 31.7275 20.4439 31.9489 19.9621 31.7143L16.2165 29.9126C15.9747 29.7967 15.7928 29.5929 15.7038 29.3396C15.6148 29.0863 15.6308 28.8132 15.7467 28.5714C15.9121 28.2242 16.2681 28.0005 16.6511 28.0005C16.8033 28.0005 16.9494 28.0352 17.0879 28.1016L20.8335 29.9049C21.0742 30.0214 21.2571 30.2247 21.3461 30.478C21.4352 30.7308 21.4192 31.0033 21.3033 31.2451Z" fill="currentColor"/>
    <path d="M26.0439 28.6714V39.5275C26.0439 39.6137 26.0879 39.6945 26.1615 39.7407C26.2016 39.7659 26.2478 39.7786 26.295 39.7786C26.3324 39.7786 26.3698 39.7709 26.4044 39.7538L36.5698 34.8593C36.6566 34.8176 36.7121 34.7297 36.7121 34.633V23.7813C36.7121 23.6951 36.6681 23.6148 36.5945 23.5687C36.5203 23.5231 36.4291 23.5165 36.3527 23.5555L26.1863 28.4456C26.0994 28.4868 26.0439 28.5753 26.0439 28.6714Z" fill="currentColor"/>
    <path d="M21.3472 24.822C21.3472 24.9181 21.4027 25.0066 21.489 25.0483L24.9302 26.7049C24.9648 26.722 25.0022 26.7297 25.0396 26.7297C25.0769 26.7297 25.1137 26.722 25.1478 26.7049L34.9302 22.0033C35.017 21.9615 35.0725 21.8731 35.0725 21.7769C35.0725 21.6808 35.017 21.5923 34.9302 21.5505L31.4923 19.8956C31.4225 19.8615 31.3434 19.8615 31.2747 19.8956L21.4896 24.5956C21.4027 24.6374 21.3472 24.7253 21.3472 24.822Z" fill="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: 'HomeIcon',
  props: {
    size: {
      type: String,
      default: '1.5rem',
    },
  },
};
</script>
