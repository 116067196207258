<template>
  <svg
    width="28"
    height="37"
    viewBox="0 0 28 37"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_74_179)">
      <path
        d="M13.1451 18.5072C12.7838 17.351 12.791 15.118 13.0006 15.118C13.6076 15.118 13.5498 17.7846 13.1451 18.5072ZM13.0223 21.9182C12.4658 23.3779 11.7721 25.0473 10.9699 26.4492C12.2924 25.9434 13.7883 25.2063 15.5154 24.8666C14.5977 24.1729 13.716 23.1756 13.0223 21.9182ZM6.22207 30.9369C6.22207 30.9947 7.17598 30.5467 8.74414 28.0318C8.25996 28.4871 6.64121 29.8023 6.22207 30.9369ZM17.9219 11.5625H27.75V35.2656C27.75 36.2268 26.9768 37 26.0156 37H1.73438C0.773242 37 0 36.2268 0 35.2656V1.73438C0 0.773242 0.773242 0 1.73438 0H16.1875V9.82812C16.1875 10.782 16.968 11.5625 17.9219 11.5625ZM17.3438 23.9777C15.8984 23.0961 14.9373 21.882 14.258 20.0898C14.5832 18.7529 15.0963 16.7223 14.7061 15.4504C14.3664 13.3258 11.642 13.5354 11.2518 14.959C10.8904 16.2814 11.2229 18.1459 11.8371 20.5234C10.9988 22.518 9.76309 25.1918 8.88867 26.7238C8.88144 26.7238 8.88145 26.7311 8.87422 26.7311C6.91582 27.7355 3.55547 29.9469 4.93574 31.6451C5.34043 32.1437 6.09199 32.3678 6.48945 32.3678C7.78301 32.3678 9.06934 31.067 10.9049 27.9018C12.7693 27.2875 14.8145 26.5215 16.6139 26.2252C18.182 27.0779 20.0176 27.6344 21.2389 27.6344C23.349 27.6344 23.4936 25.3219 22.6625 24.498C21.658 23.5152 18.7385 23.7971 17.3438 23.9777ZM27.2441 7.58789L20.1621 0.505859C19.8369 0.180664 19.3961 0 18.9336 0H18.5V9.25H27.75V8.80918C27.75 8.35391 27.5693 7.91309 27.2441 7.58789ZM21.8893 26.0373C22.1855 25.8422 21.7086 25.1773 18.7963 25.3869C21.4773 26.5287 21.8893 26.0373 21.8893 26.0373Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_74_179">
        <rect width="27.75" height="37" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'PdfIcon',
  props: {
    size: {
      type: String,
      default: '1.5rem',
    },
  },
};
</script>
