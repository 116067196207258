<template>
  <div class="updates-ov">
    <ul class="updates-list flex fd-c fgap2">
      <li v-for="update in pagedUpdates" :key="update.id" class="relative">
        <div v-if="update.concluido === true" class="update-complete text-primary offwhite-bg">
          <svg fill="none" height="13" viewBox="0 0 16 13" width="16"
               xmlns="http://www.w3.org/2000/svg">
            <path d="M2 5.5L6.5 10L14.5 2" stroke="currentColor" stroke-linecap="round"
                  stroke-width="3"/>
          </svg>
        </div>
        <div v-else class="update-incomplete text-primary offwhite-bg">
          <svg fill="none" height="4" viewBox="0 0 10 4" width="10"
               xmlns="http://www.w3.org/2000/svg">
            <path d="M2 2H8" stroke="currentColor" stroke-linecap="round" stroke-width="3"/>
          </svg>
        </div>

        <p v-if="update.attribute !== 'diNumber'" class="mb-1">
          PRI{{ update.identification }} - {{ update.label }}
          {{ update.value | moment('DD/MM/YYYY') }}</p>
        <p v-else class="mb-1">PRI{{ update.identification }} - {{ update.label }}
          {{ update.value }}</p>
        <p class="text-primary text-bold mb-1">{{ update.description }}</p>
        <p class="text-primary text-md">{{ update.created | moment('DD/MM/YYYY \à\\s HH:MM') }} por
          {{ update.createdBy }}</p>
      </li>
      <li>
        <a v-if="showPageButton" class="btn solid primary icon-right" href="#"
           @click.prevent="page = page + 1">
          <span class="text-normal text-nm">
            VISUALIZAR MAIS
            <ArrowRightIcon/>
          </span>
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
import { ArrowRightIcon } from '@/components/Icons';

export default {
  name: 'UpdatesList',
  components: {
    ArrowRightIcon,
  },
  data() {
    return {
      page: 1,
    };
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    showPageButton() {
      return !((5 * this.page) >= this.data.length);
    },
    pagedUpdates() {
      return this.data.slice(0, 5 * this.page);
    },
  },
};
</script>

<style scoped>
.updates-ov {
  height: 450px;
  overflow: auto;
}

.updates-list {
  position: relative;
}

.updates-list li {
  padding-left: 60px;
}

.updates-list::before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 30px;
  border: 2px dashed var(--accent-color);
  transform: translate3d(-50%, 0, 0);
}

.updates-list::after {
  content: '';
  display: block;
  position: absolute;
  width: 32px;
  height: 32px;
  background: url("data:image/svg+xml;charset=utf8, %3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23DBAF57' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-down'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E") center center / cover no-repeat;
  top: calc(100% - 10px);
  bottom: 0;
  left: 30px;
  transform: translate3d(-50%, 0, 0);
}

.update-complete,
.update-incomplete {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid var(--accent-color);
  border-radius: var(--medium-border-radius);
  width: 2rem;
  height: 2rem;
  position: absolute;
  top: 0;
  left: 30px;
  transform: translate3d(-50%, 0, 0);
}
</style>
