<template>
  <svg :width="size" :height="size" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.3325 7.19381C13.4677 7.19381 14.3879 6.27359 14.3879 5.13844C14.3879 4.00329 13.4677 3.08307 12.3325 3.08307C11.1974 3.08307 10.2772 4.00329 10.2772 5.13844C10.2772 6.27359 11.1974 7.19381 12.3325 7.19381Z" fill="currentColor"/>
    <path d="M12.3325 14.3876C13.4677 14.3876 14.3879 13.4674 14.3879 12.3322C14.3879 11.1971 13.4677 10.2769 12.3325 10.2769C11.1974 10.2769 10.2772 11.1971 10.2772 12.3322C10.2772 13.4674 11.1974 14.3876 12.3325 14.3876Z" fill="currentColor"/>
    <path d="M12.3325 21.5814C13.4677 21.5814 14.3879 20.6612 14.3879 19.526C14.3879 18.3909 13.4677 17.4706 12.3325 17.4706C11.1974 17.4706 10.2772 18.3909 10.2772 19.526C10.2772 20.6612 11.1974 21.5814 12.3325 21.5814Z" fill="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: 'VerticalDotsIcon',
  props: {
    size: {
      type: String,
      default: '1.5rem',
    },
  },
};
</script>
