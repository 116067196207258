<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 -960 960 960" fill="none"><path d="M382-240 154-468l57-57 171 171 367-367 57 57-424 424Z" fill="currentColor"/></svg>
</template>

<script>
export default {
  name: 'CheckIcon',
  props: {
    size: {
      type: String,
      default: '1.5rem',
    },
  },
};
</script>
