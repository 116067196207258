import { render, staticRenderFns } from "./StepCard.vue?vue&type=template&id=753c64d6&scoped=true"
import script from "./StepCard.vue?vue&type=script&lang=js"
export * from "./StepCard.vue?vue&type=script&lang=js"
import style0 from "./StepCard.vue?vue&type=style&index=0&id=753c64d6&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "753c64d6",
  null
  
)

export default component.exports