<template>
  <svg :width="size" :height="size" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M14.1187 22.3264C14.1027 22.3357 14.0852 22.3396 14.0698 22.3495C13.7357 22.5599 13.533 22.9264 13.533 23.3214V34.4088C13.533 34.8495 13.7852 35.2505 14.1824 35.4429L24.4824 40.4022C24.6407 40.478 24.8115 40.5148 24.9808 40.5148C25.1428 40.5148 25.3022 40.4692 25.4522 40.4011C25.461 40.4011 25.4692 40.406 25.478 40.4022L35.7819 35.4429C36.1786 35.2505 36.4319 34.8495 36.4319 34.4088V23.3473C36.4319 23.3396 36.4352 23.333 36.4352 23.3247C36.4357 22.8841 36.183 22.4813 35.7852 22.2901L25.4835 17.3313C25.1698 17.1813 24.8038 17.1808 24.4885 17.3313L14.1835 22.2791C14.1533 22.2934 14.1341 22.3093 14.1187 22.3264ZM32.6357 23.322L30.8247 24.194L24.9813 27L22.4791 25.7956L30.1357 22.1187L32.6357 23.322ZM15.8275 25.1473L23.833 29.0033V37.5418L15.8275 33.6868V25.1473ZM26.128 37.5412V29.0027L32.0692 26.1418L34.1368 25.1495V33.6868L26.128 37.5412ZM24.9852 19.6385L27.489 20.844L19.8324 24.5203L17.328 23.3148L24.9852 19.6385Z" fill="currentColor"/>
    <path d="M21.7077 29.6253L18.6654 28.1621C18.094 27.889 17.4082 28.1275 17.1335 28.6989C16.8588 29.2698 17.0994 29.955 17.6703 30.2302L20.7132 31.6934C20.8736 31.7703 21.0429 31.8066 21.2093 31.8066C21.6363 31.8066 22.0467 31.5676 22.2445 31.1566C22.5187 30.5857 22.2786 29.8995 21.7077 29.6253Z" fill="currentColor"/>
    <path d="M48.4204 44.0983H44.8868L44.8857 21.8225C44.8929 21.6324 44.8901 20.6472 44.1627 19.8934C43.8324 19.5511 43.2346 19.144 42.2583 19.144H38.0324C37.3989 19.144 36.8852 19.6571 36.8852 20.2912C36.8852 20.9253 37.3989 21.4385 38.0324 21.4385H42.2583C42.4632 21.4385 42.5879 21.4385 42.5912 21.7813V44.0989H7.37255V21.5747C7.37255 21.5005 7.43354 21.4379 7.50717 21.4379H12.1967C12.8308 21.4379 13.3445 20.9247 13.3445 20.2907C13.3445 19.6566 12.8308 19.1434 12.1967 19.1434H7.50717C6.16761 19.1434 5.07805 20.2341 5.07805 21.5742V44.0978H1.54563C0.912113 44.0978 0.397827 44.6115 0.397827 45.245V47.5544C0.397827 47.917 0.569256 48.2577 0.858816 48.4742C1.19123 48.722 2.92585 49.9588 4.46431 49.9588H45.4989C47.0396 49.9588 48.7742 48.722 49.1061 48.4742C49.3962 48.2577 49.5671 47.917 49.5671 47.5544V45.245C49.5676 44.6115 49.0539 44.0983 48.4204 44.0983ZM47.2731 46.945C46.6945 47.3016 45.944 47.6648 45.4989 47.6648H4.46431C4.0209 47.6648 3.27035 47.3011 2.69343 46.945V46.3923H47.2731V46.945Z" fill="currentColor"/>
    <path d="M16.8858 14.4632C17.1099 14.6868 17.4033 14.7989 17.6973 14.7989C17.9907 14.7989 18.2841 14.6868 18.5083 14.4632L23.1577 9.81373C23.6061 9.36538 23.6061 8.63901 23.1577 8.19066C22.7094 7.7434 21.983 7.7434 21.5352 8.19066L18.8434 10.883V1.46538C18.8434 0.831317 18.3297 0.31813 17.6962 0.31813C17.0621 0.31813 16.5484 0.831317 16.5484 1.46538V10.8802L13.8583 8.19011C13.4099 7.74286 12.683 7.74286 12.2347 8.19011C11.7869 8.63846 11.7869 9.36483 12.2347 9.81318L16.8858 14.4632Z" fill="currentColor"/>
    <path d="M27.621 7.26264C27.9144 7.26264 28.2078 7.15055 28.4325 6.92692L31.1226 4.23681V13.6522C31.1226 14.2863 31.6369 14.7994 32.2704 14.7994C32.9039 14.7994 33.4177 14.2863 33.4177 13.6522V4.23516L36.1094 6.92692C36.5572 7.37417 37.2842 7.37417 37.732 6.92692C38.1803 6.47912 38.1803 5.7522 37.732 5.30384L33.082 0.653845C32.6336 0.206592 31.9067 0.206592 31.4589 0.653845L26.8089 5.3033C26.3611 5.75165 26.3611 6.47857 26.8089 6.92637C27.0331 7.15055 27.3265 7.26264 27.621 7.26264Z" fill="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: 'ProcessIcon',
  props: {
    size: {
      type: String,
      default: '1.5rem',
    },
  },
};
</script>
