<template>
  <header class="flex ai-c jc-sb">
    <form class="header-search relative" @submit.prevent="generalSearch">
      <Input
        v-model="search"
        className="header-search-input no-border"
        name="search"
        placeholder="Pesquisar em Processos"
        type="search"
      />
      <button type="submit">
        <SearchIcon/>
      </button>
    </form>

    <div class="flex ai-c">
      <!--      <Notifications />-->

      <!--      <a href="#">-->
      <!--        <LogoutIcon size="2rem" />-->
      <!--      </a>-->

      <UserMenu/>
    </div>
    <ModalProcess
      v-if="process !== null"
      :data="modalData"
      :handler="modalProcessOpen"
      @request-close="closeModal"
    />
  </header>
</template>

<script>

import { mapActions, mapState } from 'vuex';

import {
  SearchIcon,
  // LogoutIcon,
} from '@/components/Icons';

import Input from '@/components/DataInput/Input.vue';
// import Notifications from '@/components/Notifications.vue';
import UserMenu from '@/components/UserMenu.vue';
import api from '../services/api';
import ModalProcess from './ModalProcess.vue';

export default {
  name: 'Header',
  components: {
    // Icons
    SearchIcon,
    // LogoutIcon,
    // Components
    Input,
    // Notifications,
    UserMenu,
    ModalProcess,
  },
  data() {
    return {
      search: '',
      modalProcessOpen: false,
      modalData: {
        containers: [],
        documents: [],
      },
    };
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapState('process', ['steps', 'processes', 'process', 'tableColumns', 'stepColumns', 'generalFilter']),
  },
  methods: {
    ...mapActions('process', [
      'getStepsAmount',
      'getSteps',
      'getProcesses',
      'getProcess',
      'clearProcess',
      'clearSingleProcess',
      'getProcessContainers',
      'getProcessDocuments',
      'updateTableColumns',
      'syncTableColumns',
      'setGeneralFilter',
      'updateProcesses',
    ]),
    ...mapActions(['toggleLoading', 'toggleHideHeader', 'toggleHideSidebar']),
    async generalSearch() {
      if (this.search !== null && this.search !== '' && this.search !== undefined) {
        this.toggleLoading(true);

        try {
          const despachantes = await api.get(`/api/public/partner/getCompanies/${this.user.person.id}`);
          const despachante = despachantes.data.data[0];
          const result = await api.get(`/newImportProcess/general-search?search=${this.search}&customBrokerId=${despachante.id}`);

          if (result.data.data.length === 1) {
            this.setGeneralFilter(false);
            const process = result.data.data[0];

            await this.getProcess(process.identification);

            this.modalData.containers = await this.getProcessContainers(
              process.id,
            );
            this.modalData.documents = await this.getProcessDocuments(
              process.id,
            );

            this.toggleLoading(false);

            this.modalProcessOpen = true;
          } else if (result.data.data.length > 1) {
            this.setGeneralFilter(true);
            this.updateProcesses(result.data.data);

            this.toggleLoading(false);
            this.$router.push('/processos');
          } else if (result.data.data.length === 0) {
            this.toggleLoading(false);
            this.setGeneralFilter(false);
            this.$toast.warning('Nenhum processo encontrado.', {
              position: 'top-center',
            });
          }
        } catch (err) {
          this.toggleLoading(false);
          this.setGeneralFilter(false);
          this.$toast.warning('Nenhum processo encontrado.', {
            position: 'top-center',
          });
        }
      }
    },
    closeModal() {
      this.clearSingleProcess();
      this.modalProcessOpen = false;
      this.modalData = {
        containers: [],
        documents: [],
      };
    },
  },
};
</script>

<style scoped>
.header-search button {
  background-color: transparent;
  border: 0;
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 3rem;
  text-align: center;
}
</style>
