<template>
  <div class="relative">
    <label v-if="label != ''" :for="name" class="flex ai-c jc-sb">
      {{label}}
      <router-link v-if="extraLink != ''" :to="extraLink" class="text-primary">
        Clique aqui se você esqueceu sua senha!
      </router-link>
    </label>
    <div :class="[
      prefix !== '' && 'relative with-prefix',
      'input-cont-hint'
    ]">
      <input
        class="styled-input"
        v-if="mask.length > 0"
        :class="error.message != '' ? `${className} has-error` : `${className}`"
        :id="name"
        :type="type"
        :name="name"
        :required="required"
        :placeholder="placeholder"
        :value="value"
        @input="$emit('input', $event.target.value)"
        @keyup="$emit('inputChanged', $event.target.value)"
        v-mask="mask"
        :disabled="disabled"
        :style="addStyle"
      >

      <input
        class="styled-input"
        v-else-if="money"
        :class="error.message != '' ? `${className} has-error` : `${className}`"
        :id="name"
        :type="type"
        :name="name"
        :required="required"
        :placeholder="placeholder"
        :value="value"
        v-money="moneyFormat"
        @input="$emit('input', $event.target.value)"
        @keyup="$emit('inputChanged', $event.target.value)"
        :disabled="disabled"
        :style="addStyle"
      >

      <input
        class="styled-input"
        v-else
        :class="error.message != '' ? `${className} has-error` : `${className}`"
        :id="name"
        :type="type"
        :name="name"
        :required="required"
        :placeholder="placeholder"
        :value="value"
        @input="$emit('input', $event.target.value)"
        @keyup="$emit('inputChanged', $event.target.value)"
        :disabled="disabled"
        :style="addStyle"
      >

      <div class="prefix">
        {{prefix}}
      </div>
    </div>

    <div v-if="error.message != ''" class="field-error mt-1">
      {{error.message}}
    </div>

    <div class="input-hint" v-if="hint">
      <p class="text-medium text-white">{{ hintMessage }}</p>
    </div>
  </div>
</template>

<script>
import { mask } from 'vue-the-mask';
import { VMoney } from 'v-money';

export default {
  name: 'StyledInput',
  directives: {
    mask,
    money: VMoney,
  },
  data() {
    return {
      moneyFormat: {
        decimal: ',',
        thousands: '.',
      },
    };
  },
  props: {
    label: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'text',
    },
    required: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
    error: {
      type: Error,
      default: () => new Error(''),
    },
    value: {},
    extraLink: {
      type: String,
      default: '',
    },
    className: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    mask: {
      type: Array,
      default: () => [],
    },
    money: {
      type: Boolean,
      default: false,
    },
    prefix: {
      type: String,
      default: '',
    },
    addStyle: {
      type: String,
      default: '',
    },
    hint: {
      type: Boolean,
      default: false,
    },
    hintMessage: {
      type: String,
      default: '',
    },
  },
};
</script>

<style scoped>
  .styled-input {
    border: var(--small-border-width) solid var(--primary-color) !important;
    border-radius: var(--round-border-radius) !important;
    padding: 0.3rem 3rem 0.3rem 1.5rem !important;
    color: var(--primary-color) !important;
    height: 50px !important;
  }

  .with-prefix input {
    padding-left: 40px;
  }

  .prefix {
    position: absolute;
    width: 40px;
    top: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .input-hint {
    position: absolute;
    bottom: calc(100% + 15px);
    text-align: left;
    opacity: 0;
    visibility: hidden;
    transition: var(--all-transition);
  }

  .input-hint::after {
    content: '';
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
    position: absolute;
    top: 100%;
  }

  .input-cont-hint:hover ~ .input-hint {
    opacity: 1;
    visibility: visible;
  }
</style>
