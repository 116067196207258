<template>
  <svg :width="size" :height="size" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M49.3164 17.0703L45.4492 13.2031C44.5312 12.2851 43.0469 12.2851 42.1387 13.2031L18.75 36.5918L7.87109 25.7031C6.95312 24.7851 5.46875 24.7851 4.56055 25.7031L0.683594 29.5801C-0.234375 30.498 -0.234375 31.9824 0.683594 32.9004L17.0898 49.3164C18.0078 50.2343 19.4922 50.2343 20.4004 49.3164L49.3066 20.3906C50.2246 19.4629 50.2246 17.9785 49.3164 17.0703ZM17.6465 27.4219C18.252 28.0371 19.248 28.0371 19.8535 27.4219L40.166 7.08984C40.7715 6.47461 40.7715 5.48828 40.166 4.88281L35.752 0.458984C35.1465 -0.15625 34.1504 -0.15625 33.5449 0.458984L18.75 15.2539L13.3398 9.83398C12.7344 9.21875 11.7383 9.21875 11.1328 9.83398L6.70898 14.2578C6.10352 14.873 6.10352 15.8594 6.70898 16.4648L17.6465 27.4219Z" fill="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: 'DoubleCheckIcon',
  props: {
    size: {
      type: String,
      default: '1.5rem',
    },
  },
};
</script>
