import Vue from 'vue';
import api from '@/services/api';
import { sanitizeProcessData } from '@/utils/process-data';
// eslint-disable-next-line import/no-cycle
import { actions, handleSocketError, handleSocketMessage } from '@/services/webocket';
import authStore from '@/store/Auth';
import { labels } from '../../utils/DateLabelsUtil';

export default {
  connectWs({ state, dispatch }) {
    state.socket = new WebSocket(process.env.VUE_APP_WEBSOCKET_URL);
    state.socket.addEventListener('message', (event) => handleSocketMessage(event, state));
    state.socket.addEventListener('error', (event) => handleSocketError(event, state));
    state.socket.addEventListener('close', () => dispatch('handleSocketClose'));
  },
  handleSocketClose({ dispatch }) {
    console.log('Websocket fechado. Tentando reconectar em 5 segundos...');
    setTimeout(() => {
      dispatch('connectWs');
    }, 5000);
  },
  async clearSingleProcess({ commit, state }) {
    if (state.openedProcesses[state.process.id] && state.openedProcesses[state.process.id].user === authStore.state.user.person.name) {
      state.socket.send(
        JSON.stringify({
          action: actions.CLOSE_PROC,
          data: {
            [state.process.id]: authStore.state.user.person.name,
          },
        }),
      );
    }
    commit('CLEAR_PROCESS');
  },
  async getProcess(context, payload) {
    try {
      const response = await api.get(`/api/public/partner/processes/${payload}`);
      if (response) {
        const { data } = response.data;

        if (data.length > 0) {
          if (data.length === 1) {
            context.commit('UPDATE_PROCESS', sanitizeProcessData(data[0]));
          } else {
            alert('Foi encontrado mais de um processo!');
          }
        }
      }
    } catch (err) {
      Vue.$toast.error('Não foi possível recuperar o processo!');
    }
  },
  async getProcessById({ commit, state }, payload) {
    try {
      const response = await api.get(`/api/public/partner/process-by-id/${payload}`);
      if (response) {
        const { data } = response.data;

        commit('UPDATE_PROCESS', sanitizeProcessData(data));

        if (!state.openedProcesses[payload]) {
          state.socket.send(
            JSON.stringify({
              action: actions.OPEN_PROC,
              data: {
                [data.id]: authStore.state.user.person.name,
              },
            }),
          );
        }
      }
    } catch (err) {
      console.log(err);
      Vue.$toast.error('Não foi possível recuperar o processo!');
    }
  },
  setGeneralFilter(context, payload) {
    context.commit('UPDATE_GENERAL_FILTER', payload);
  },
  async getProcesses(context, payload) {
    try {
      // context.dispatch('toggleLoading', true, { root: true });
      // const response = await api.get(`/api/public/partner/processes/search?stepsIds=${payload.stepId}`);
      const responseProcesses = await api.post(`/newImportProcess?steps=${payload.stepId}`);
      // console.log(responseProcesses);
      if (responseProcesses) {
        Vue.$toast.success(`Foram encontrados ${responseProcesses.data.data.length} processos de importação`, { duration: 5000 });
        context.commit('UPDATE_PROCESSES', responseProcesses.data.data);
      }
      // context.dispatch('toggleLoading', false, { root: true });
    } catch (err) {
      context.commit('UPDATE_PROCESSES', []);
      // context.dispatch('toggleLoading', false, { root: true });
      Vue.$toast.error('Não foi possível recuperar os processos!');
    }
  },

  async getSteps(context) {
    try {
      const response = await api.get('/api/public/partner/steps');
      context.commit('UPDATE_STEPS', response.data.data.filter((step) => step.id !== 8));
    } catch (err) {
      Vue.$toast.error('Não foi possível recuperar as etapas de processo!');
    }
  },

  async getStepsAmount(context) {
    try {
      const response = await api.get('/api/public/partner/processes/steps/amount');
      context.commit('UPDATE_STEPS_AMOUNT', response.data.data);
    } catch (err) {
      Vue.$toast.error('Não foi possível recuperar as estatísticas das etapas de processo!');
    }
  },

  async getProcessContainers(context, payload) {
    try {
      const response = await api.get(`/api/public/partner/processes/${payload}/containers`);
      return response.data.data;
    } catch (err) {
      Vue.$toast.error('Não foi possível recuperar os containers do processo!');
      return null;
    }
  },

  async updateProcessJsonDataCustomer(context, payload) {
    try {
      const response = await api.get(`/integrations/duasRodas/consultarEmbarque/${payload}`);
      context.commit('UPDATE_JSON_DATA_CUSTOMER', JSON.parse(response.data.data));
      return null;
    } catch (err) {
      Vue.$toast.error('Não foi possível recuperar os containers do processo!');
      return null;
    }
  },

  async getProcessDocuments(context, payload) {
    try {
      const response = await api.get(`/api/public/partner/processes/${payload}/documents`);
      return response.data.data;
    } catch (err) {
      Vue.$toast.error('Não foi possível recuperar os documentos do processo!');
      return null;
    }
  },

  updateSteps(context, payload) {
    context.commit('UPDATE_STEPS', payload);
  },

  clearProcess(context) {
    context.commit('UPDATE_PROCESSES', []);
  },

  updateTableColumns(context, payload) {
    context.commit('UPDATE_TABLE_COLUMNS', payload);
  },

  syncTableColumns(context, payload) {
    return new Promise((resolve) => {
      resolve(true);
    }).then(() => {
      context.commit('SYNC_TABLE_COLUMNS', payload);
    });
  },

  sortTableColumns(context, payload) {
    context.commit('SORT_TABLE_COLUMNS', payload);
  },

  async enableNoClick(context, flag) {
    context.commit('SET_NOCLICK', flag);
  },

  toggleModalAction(context, payload) {
    context.commit('UPDATE_MODAL_ACTION', payload);
  },

  async patchProcess(context, payload) {
    if (this.timer) {
      clearTimeout(this.timer);
    }

    this.timer = setTimeout(async () => {
      context.dispatch('toggleLoading', true, { root: true });
      const response = await api.patch(`/api/public/partner/processes/${payload.data.id}`, payload.data);

      if (response.data.code === 'SUCCESS') {
        const eventResponse = await api.get(`/api/public/partner/processes/checkEvent/${payload.data.id}/${payload.fieldUpdated}/check/true`);
        if (eventResponse.data.listOfMessages !== null && eventResponse.data.listOfMessages.length > 0) {
          context.commit('UPDATE_MODAL_ACTION', true);
          context.commit('UPDATE_MODAL_ACTION_TITLE', eventResponse.data.message);
          context.commit('UPDATE_ACTIONS_MESSAGES', eventResponse.data.listOfMessages);
          context.commit('UPDATE_ACTION_FIELD', payload.fieldUpdated);
        }
      } else {
        context.commit('UPDATE_MODAL_ACTION', false);
        context.commit('UPDATE_ACTIONS_MESSAGES', null);
      }
      context.dispatch('toggleLoading', false, { root: true });
    }, 1600);
  },

  async verifyDocActions(context, payload) {
    const eventResponse = await api.get(`/api/public/partner/processes/checkEvent/${payload.identification}/${payload.docType}/check/${payload.verifyDoc}`);
    if (eventResponse.data.listOfMessages !== null && eventResponse.data.listOfMessages.length > 0) {
      context.commit('UPDATE_MODAL_ACTION', true);
      context.commit('UPDATE_MODAL_ACTION_TITLE', eventResponse.data.message);
      context.commit('UPDATE_ACTIONS_MESSAGES', eventResponse.data.listOfMessages);
      context.commit('UPDATE_ACTION_FIELD', payload.docType);
    } else {
      context.commit('UPDATE_MODAL_ACTION', false);
      context.commit('UPDATE_ACTIONS_MESSAGES', null);
    }
  },

  async dispatchProcessAction(context) {
    console.log('context: ', context);
    await context.dispatch('toggleLoading', true, { root: true });
    const eventResponse = await api.get(`/api/public/partner/processes/checkEvent/${context.state.process.id}/${context.state.actionField}/check/false`);

    let textComplement = '';
    if (!isNaN(context.state.actionField)) {
      textComplement = ' e envie o arquivo novamente';
    }

    if (eventResponse.data.data && eventResponse.data.data.length > 0) {
      for (let i = 0; i < eventResponse.data.data.length; i += 1) {
        const msg = eventResponse.data.data[i];

        if (msg.code === 'ERROR') {
          msg.message = `<p>${msg.message}</p>`;
          if (msg.data && msg.data.length > 0) {
            const items = msg.data.map((item) => labels[0][item].label);
            msg.message += `<p>Preencha o(s) campo(s) ${items.join(', ')}${textComplement}.</p>`;
          }

          Vue.prototype.$modal.danger().show({
            title: 'Não foi possível enviar o Follow Up',
            text: msg.message,
            cancelText: 'Fechar',
          });
        } else {
          Vue.$toast.success(msg.message);
        }
      }
    }
    context.commit('RESET_PROCESS_ACTION');
    await context.dispatch('toggleLoading', false, { root: true });
  },

  async cancelProcessAction(context) {
    context.commit('RESET_PROCESS_ACTION');
  },

  async updateProcesses(context, payload) {
    context.commit('UPDATE_PROCESSES', payload);
  },

  updateConnections(context, payload) {
    context.commit('UPDATE_CONNECTIONS', payload);
  },

};
