var render = function render(){var _vm=this,_c=_vm._self._c;return _c('aside',{class:[
  'main-sidebar flex fd-c jc-sb fgap2',
  _vm.hideSidebar ? 'hide' : ''
]},[_c('div',{staticClass:"flex fd-c fgap2"},[_c('div',{staticClass:"text-center p-1"},[_c('router-link',{attrs:{"to":{ name: 'Home' }}},[_c('img',{attrs:{"src":require("@/assets/img/logo.png"),"alt":"Prime Internacional"}})])],1),_c('ul',{staticClass:"navigation"},[_c('p',{staticClass:"text-center text-light text-md"},[_vm._v("Menu")]),_c('li',[_c('router-link',{attrs:{"to":{ name: 'Home' }}},[_c('span',{staticClass:"material-symbols-outlined",staticStyle:{"font-size":"3.5rem"}},[_vm._v(" dashboard ")])]),_vm._m(0)],1),_c('li',[_c('router-link',{attrs:{"to":{ name: 'Processes' }}},[_c('span',{staticClass:"material-symbols-outlined",staticStyle:{"font-size":"3.5rem"}},[_vm._v(" conveyor_belt ")])]),_vm._m(1)],1),_c('li',[_c('router-link',{attrs:{"to":{ name: 'ProcessesReport' }}},[_c('span',{staticClass:"material-symbols-outlined",staticStyle:{"font-size":"3.5rem"}},[_vm._v(" lab_profile ")])]),_vm._m(2)],1)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"nav-tooltip"},[_c('span',{staticClass:"text-medium"},[_vm._v("Dashboard")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"nav-tooltip"},[_c('span',{staticClass:"text-medium"},[_vm._v("Processos")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"nav-tooltip"},[_c('span',{staticClass:"text-medium"},[_vm._v("Relatórios")])])
}]

export { render, staticRenderFns }