<template>
  <svg :width="size" :height="size" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M5.88377 15L9.81752 18.9325C10.0619 19.1769 10.0619 19.5731 9.81752 19.8175C9.57313 20.0619 9.1769 20.0619 8.93252 19.8175L3.93252 14.8175C3.81497 14.7002 3.7489 14.541 3.7489 14.375C3.7489 14.209 3.81497 14.0498 3.93252 13.9325L8.93252 8.9325C9.1769 8.68811 9.57313 8.68811 9.81752 8.9325C10.0619 9.17688 10.0619 9.57311 9.81752 9.8175L5.88377 13.75H19.375C19.7202 13.75 20 14.0298 20 14.375C20 14.7202 19.7202 15 19.375 15H5.88377ZM13.125 5C12.7798 5 12.5 4.72018 12.5 4.375C12.5 4.02982 12.7798 3.75 13.125 3.75H23.125C24.8509 3.75 26.25 5.14911 26.25 6.875V23.125C26.25 24.8509 24.8509 26.25 23.125 26.25H13.125C12.7798 26.25 12.5 25.9702 12.5 25.625C12.5 25.2798 12.7798 25 13.125 25H23.125C24.1606 25 25 24.1605 25 23.125V6.875C25 5.83947 24.1606 5 23.125 5H13.125Z" fill="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: 'LogoutIcon',
  props: {
    size: {
      type: String,
      default: '1.5rem',
    },
  },
};
</script>
