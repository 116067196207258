<template>
  <svg :width="size" :height="size" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M48.7725 7.63295C48.7725 7.16921 48.4401 6.74668 48.0225 6.54558L37.161 1.3324C36.8302 1.17306 36.4286 1.17251 36.0978 1.33185L25.2549 6.53404C25.2264 6.54723 25.2093 6.56481 25.1896 6.58075C25.1709 6.59119 25.2286 6.59558 25.2115 6.60712C24.8599 6.828 24.7253 7.21371 24.7253 7.62855V19.2846C24.7253 19.7478 24.9093 20.1703 25.3269 20.3714L36.117 25.5857C36.2824 25.6654 36.4412 25.7038 36.6198 25.7038C36.7907 25.7038 36.9472 25.6566 37.106 25.5846C37.1154 25.5846 37.1181 25.5901 37.1275 25.5857L48.0885 20.3714C48.5049 20.1703 48.9011 19.7478 48.9011 19.2846V7.66042C48.9011 7.65108 48.7725 7.64284 48.7725 7.63295ZM36.5994 11.4961L33.9692 10.2296L42.0181 6.36426L44.6456 7.6291L42.9137 8.46371L36.5994 11.4961ZM36.6027 3.75767L39.2341 5.02525L31.1863 8.89009L28.5538 7.62251L36.6027 3.75767ZM26.9231 9.54833L35.1648 13.6016V22.578L26.9231 18.5258V9.54833ZM37.9121 22.5785V13.6022L44.2352 10.6478L46.7033 9.55163V18.5264L37.9121 22.5785Z" fill="currentColor"/>
    <path d="M33.1571 14.2566L29.9599 12.7181C29.3588 12.4286 28.6385 12.6824 28.3494 13.283C28.0604 13.883 28.3143 14.6033 28.9143 14.8923L32.1115 16.4308C32.2797 16.5115 32.4582 16.55 32.6335 16.55C33.0819 16.55 33.5137 16.2983 33.7209 15.8659C34.0104 15.2659 33.7577 14.5451 33.1571 14.2566Z" fill="currentColor"/>
    <path d="M39.8352 26.283C39.1676 26.283 38.4615 26.8225 38.4615 27.489V44.939C38.4615 46.3852 37.2654 47.8022 35.8203 47.8022H3.84615V7.6923H9.89011V11.5385H20.7571C21.4236 11.5385 21.9632 11.106 21.9632 10.4396C21.9632 9.77362 21.4236 9.34065 20.7571 9.34065H12.6374V4.94505H16.3187L16.65 4.50054C17.2681 3.24889 18.506 2.58571 19.8824 2.58571C21.2593 2.58571 22.4978 3.41977 23.1154 4.67142C23.4093 5.26757 24.1313 5.54175 24.7308 5.24725C25.328 4.95219 25.5731 4.24285 25.278 3.6456C24.2511 1.56483 22.1841 0.158783 19.8824 0.158783C17.8407 0.159882 15.9824 1.09889 14.8725 2.74724H9.89011V5.4945H2.90769C2.2412 5.4945 1.64835 6.22746 1.64835 6.8934V48.7665C1.64835 49.4335 2.2412 50 2.90769 50H35.8209C38.5962 50 41.2088 47.7148 41.2088 44.939V27.489C41.2088 26.8225 40.5016 26.283 39.8352 26.283Z" fill="currentColor"/>
    <path d="M15.767 23.1341L13.9005 21.606L10.7736 25.4247L10.0566 24.3841L8.07033 25.7538L10.6093 29.4363L15.767 23.1341Z" fill="currentColor"/>
    <path d="M10.6093 36.8665L15.767 30.5621L13.9005 29.0341L10.7742 32.8549L10.0566 31.8126L8.07033 33.1819L10.6093 36.8665Z" fill="currentColor"/>
    <path d="M10.6099 44.2967L15.7681 37.9901L13.9 36.4626L10.7736 40.2841L10.0566 39.2445L8.07033 40.6137L10.6099 44.2967Z" fill="currentColor"/>
    <path d="M28.5714 26.3736H18.1319V28.5714H28.5714V26.3736Z" fill="currentColor"/>
    <path d="M33.5165 32.4176H18.1319V35.7143H33.5165V32.4176Z" fill="currentColor"/>
    <path d="M33.5165 40.1099H18.1319V42.3077H33.5165V40.1099Z" fill="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: 'ReportIcon',
  props: {
    size: {
      type: String,
      default: '1.5rem',
    },
  },
};
</script>
