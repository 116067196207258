import moment from 'moment';
import { getTypeOfProcessReference } from '@/utils/process-data';

const today = moment(new Date(), 'YYYY-MM-DD').startOf('day');
const sevenDaysAhead = moment(new Date(), 'YYYY-MM-DD').add(7, 'days').startOf('day');
const fourteenDaysAhead = moment(new Date(), 'YYYY-MM-DD').add(14, 'days').startOf('day');

const dateComparator = {
  comparator: (filterLocalDateAtMidnight, cellValue) => {
    const dateAsString = cellValue;

    if (dateAsString === null || dateAsString === '') {
      return 0;
    }

    const dateParts = dateAsString.split('-');
    const year = Number(dateParts[0]);
    const month = Number(dateParts[1]) - 1;
    const day = Number(dateParts[2].split('T')[0]);
    const cellDate = new Date(year, month, day);

    if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
      return 0;
    }

    if (cellDate < filterLocalDateAtMidnight) {
      return -1;
    }
    if (cellDate > filterLocalDateAtMidnight) {
      return 1;
    }
    return 0;
  },
  browserDatePicker: true,
};

function validateCheck(params) {
  if (params.value === true) {
    return 'Sim';
  }
  if (params.value === false) {
    return 'Não';
  }
  return null;
}

class DatePicker {
  init(params) {
    this.eInput = document.createElement('input');
    this.eInput.classList.add('ag-input');
    this.eInput.setAttribute('type', 'date');

    let paramDate = null;

    if (params.value) {
      // eslint-disable-next-line prefer-destructuring
      paramDate = params.value.split('T')[0];
      this.eInput.setAttribute('value', paramDate);
    }
  }

  getGui() {
    return this.eInput;
  }

  afterGuiAttached() {
    this.eInput.focus();
  }

  getValue() {
    return this.eInput.value !== '' || this.eInput.value !== null ? `${this.eInput.value}T03:00:00` : null;
  }
}

const teste = {
  noClick: false,
  modalActionTitle: '',
  modalAction: false,
  actionMessages: [],
  actionField: '',
  steps: [],
  stepsAmount: [],
  processes: [],
  process: {},
  generalFilter: false,
  connections: [],
  tableColumns: [
    {
      headerName: '',
      field: 'reminder',
      sortable: true,
      editable: false,
      filter: 'agTextColumnFilter',
      filterParams: { buttons: ['reset', 'apply'] },
      floatingFilter: false,
      hide: true,
      resizable: true,
      suppressMovable: true,
      width: 50,
      cellClass: 'pointer',
      cellRenderer: (params) => {
        if (params.data.reminder) {
          return `
            <div class="flex ai-c fgap1" style="margin-top: 12px">
              <div class="table-tooltip flex ai-c fgap1">
                <svg class="text-warning" width="16" height="16" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M13.6549 3.85871C11.5616 3.85871 9.83285 2.5 7.20109 2.5C6.22629 2.5 5.35309 2.67125 4.54309 2.96926C4.65534 2.67608 4.70312 2.36216 4.68316 2.04887C4.61395 0.938087 3.70336 0.0471108 2.59133 0.0018765C1.3416 -0.0489828 0.3125 0.949063 0.3125 2.1875C0.3125 2.9307 0.683477 3.58691 1.25 3.98223V19.0625C1.25 19.5803 1.66973 20 2.1875 20H2.8125C3.33027 20 3.75 19.5803 3.75 19.0625V15.375C4.8559 14.9037 6.23367 14.5109 8.22012 14.5109C10.3134 14.5109 12.0421 15.8696 14.6739 15.8696C16.5555 15.8696 18.0593 15.2331 19.4593 14.2735C19.7984 14.0411 20 13.6551 20 13.2439V3.74785C20 2.83406 19.052 2.22949 18.2232 2.61441C16.8819 3.23738 15.2368 3.85871 13.6549 3.85871Z" fill="currentColor"/>
                </svg>
              </div>
            </div>
          `;
        }
        return '';
      },
    },
    {
      headerName: 'Ref.',
      field: 'identification',
      sortable: true,
      editable: false,
      filter: 'agTextColumnFilter',
      filterParams: { buttons: ['reset', 'apply'] },
      floatingFilter: true,
      hide: true,
      resizable: true,
      suppressMovable: true,
      width: 300,
      cellClass: 'pointer',
      cellStyle: (params) => {
        if (params.data.urgent === true) {
          return {
            'background-color': '#e74c3c',
            color: '#ffffff',
          };
        }
        return {};
      },
      cellRenderer: (params) => {
        if (teste.openedProcesses[params.data.id]) {
          return `
            <div class="flex ai-c fgap1">
              <div class="table-tooltip flex ai-c fgap1">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="transparent" stroke="#ff0000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-user"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg>
                <span class="content">${teste.openedProcesses[params.data.id].user}</span>
              </div>
              <div>${getTypeOfProcessReference(params.data)}</div>
            </div>
          `;
        }
        return `${getTypeOfProcessReference(params.data)}`;
      },
    },
    {
      headerName: 'Etapa',
      field: 'step',
      sortable: true,
      editable: false,
      filter: 'agTextColumnFilter',
      filterParams: { buttons: ['reset', 'apply'] },
      floatingFilter: true,
      hide: true,
      resizable: true,
      suppressMovable: true,
      valueFormatter: (params) => {
        if (typeof params.data.step === 'object') {
          return params.data.step.alias;
        }

        if (typeof params.data.step === 'string') {
          return params.data.step;
        }
      },
      cellStyle: (params) => {
        if (params.data.urgent === true) {
          return {
            'background-color': '#e74c3c',
            color: '#ffffff',
          };
        }
        if ((params.data.step.alias || params.data.step) === 'Todas') {
          return {
            'background-color': '#000000',
            color: '#ffffff',
          };
        }
        if ((params.data.step.alias || params.data.step) === 'Produção') {
          return {
            'background-color': '#f65694',
            color: '#ffffff',
          };
        }
        if ((params.data.step.alias || params.data.step) === 'Booking') {
          return {
            'background-color': '#3b9b28',
            color: '#ffffff',
          };
        }
        if ((params.data.step.alias || params.data.step) === 'Trânsito') {
          return {
            'background-color': '#d6b200',
            color: '#ffffff',
          };
        }
        if ((params.data.step.alias || params.data.step) === 'Desembaraço') {
          return {
            'background-color': '#3594E9',
            color: '#ffffff',
          };
        }
        if ((params.data.step.alias || params.data.step) === 'Carregamento') {
          return {
            'background-color': '#A569BD',
            color: '#ffffff',
          };
        }
        if ((params.data.step.alias || params.data.step) === 'Pendências') {
          return {
            'background-color': 'rgb(205, 97, 85)',
            color: '#ffffff',
          };
        }
        if ((params.data.step.alias || params.data.step) === 'Licenciamento') {
          return {
            'background-color': 'rgb(152, 30, 30)',
            color: '#ffffff',
          };
        }
        if ((params.data.step.alias || params.data.step) === 'Encerramento') {
          return {
            'background-color': '#d07107',
            color: '#ffffff',
          };
        }
        return {};
      },
    },
    {
      headerName: 'Status',
      field: 'status',
      sortable: true,
      editable: false,
      filter: 'agTextColumnFilter',
      filterParams: { buttons: ['reset', 'apply'] },
      floatingFilter: true,
      hide: true,
      resizable: true,
      suppressMovable: true,
      cellStyle: (params) => {
        if (params.data.urgent === true) {
          return {
            'background-color': '#e74c3c',
            color: '#ffffff',
          };
        }
        return {};
      },
    },
    {
      headerName: 'Status Despachante',
      field: 'statusCustomBroker',
      sortable: true,
      editable: false,
      filter: 'agTextColumnFilter',
      filterParams: { buttons: ['reset', 'apply'] },
      floatingFilter: true,
      hide: true,
      resizable: true,
      suppressMovable: true,
    },
    {
      headerName: 'Ref/Pedido Cliente',
      field: 'customerRef',
      sortable: true,
      editable: false,
      filter: 'agTextColumnFilter',
      filterParams: { buttons: ['reset', 'apply'] },
      floatingFilter: true,
      hide: true,
      resizable: true,
      suppressMovable: true,
    },
    {
      headerName: 'Cliente',
      field: 'customer',
      sortable: true,
      editable: false,
      filter: 'agTextColumnFilter',
      filterParams: { buttons: ['reset', 'apply'] },
      floatingFilter: true,
      // eslint-disable-next-line consistent-return
      valueFormatter: (params) => {
        if (typeof params.data.customer === 'object') {
          return params.data.customer.name;
        }
        if (typeof params.data.customer === 'string') {
          return params.data.customer;
        }
      },
      hide: true,
      resizable: true,
      suppressMovable: true,
    },
    {
      headerName: 'Adquirente',
      field: 'acquirer.name',
      sortable: true,
      editable: false,
      filter: 'agTextColumnFilter',
      filterParams: { buttons: ['reset', 'apply'] },
      floatingFilter: true,
      hide: true,
      resizable: true,
      suppressMovable: true,
    },
    {
      headerName: 'Importador',
      field: 'importer',
      sortable: true,
      editable: false,
      filter: 'agTextColumnFilter',
      filterParams: { buttons: ['reset', 'apply'] },
      floatingFilter: true,
      valueFormatter: (params) => {
        if (typeof params.data.importer === 'object') {
          return params.data.importer.name;
        }
        if (typeof params.data.importer === 'string') {
          return params.data.importer;
        }
      },
      hide: true,
      resizable: true,
      suppressMovable: true,
    },
    {
      headerName: 'Exportador',
      field: 'exporter.name',
      sortable: true,
      editable: false,
      filter: 'agTextColumnFilter',
      filterParams: { buttons: ['reset', 'apply'] },
      floatingFilter: true,
      hide: true,
      resizable: true,
      suppressMovable: true,
    },
    {
      headerName: 'Fabricante',
      field: 'manufacturer.name',
      sortable: true,
      editable: false,
      filter: 'agTextColumnFilter',
      filterParams: { buttons: ['reset', 'apply'] },
      floatingFilter: true,
      hide: true,
      resizable: true,
      suppressMovable: true,
    },
    {
      headerName: 'Vendedor / Seller',
      field: 'seller.name',
      sortable: true,
      editable: false,
      filter: 'agTextColumnFilter',
      filterParams: { buttons: ['reset', 'apply'] },
      floatingFilter: true,
      hide: true,
      resizable: true,
      suppressMovable: true,
    },
    {
      headerName: 'Despachante Aduaneiro',
      field: 'customBroker.name',
      sortable: true,
      editable: false,
      filter: 'agTextColumnFilter',
      filterParams: { buttons: ['reset', 'apply'] },
      floatingFilter: true,
      hide: true,
      resizable: true,
      suppressMovable: true,
    },
    {
      headerName: 'Filial (UF)',
      field: 'account.name',
      sortable: true,
      editable: false,
      filter: 'agTextColumnFilter',
      filterParams: { buttons: ['reset', 'apply'] },
      floatingFilter: true,
      hide: true,
      resizable: true,
      suppressMovable: true,
    },
    {
      headerName: 'Ref Importador',
      field: 'importerRef',
      sortable: true,
      editable: false,
      filter: 'agTextColumnFilter',
      filterParams: { buttons: ['reset', 'apply'] },
      floatingFilter: true,
      hide: true,
      resizable: true,
      suppressMovable: true,
    },
    {
      headerName: 'Outra Referência',
      field: 'anotherRef',
      sortable: true,
      editable: false,
      filter: 'agTextColumnFilter',
      filterParams: { buttons: ['reset', 'apply'] },
      floatingFilter: true,
      hide: true,
      resizable: true,
      suppressMovable: true,
    },
    {
      headerName: 'DUIMP',
      field: 'duimp',
      sortable: true,
      editable: false,
      filter: 'agTextColumnFilter',
      filterParams: { buttons: ['reset', 'apply'] },
      floatingFilter: false,
      hide: true,
      resizable: true,
      suppressMovable: true,
      cellRenderer: (params) => {
        if (params.data.duimp === true || params.data.duimp === 'true') {
          return `
            <div style="margin-top: 8px">
              <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#3B9B28"><path d="M382-240 154-468l57-57 171 171 367-367 57 57-424 424Z"/></svg>
            </div>
        `;
        }

        return `<div style="margin-top: 8px">
                <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#F26767">
                <path d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z"/></svg>
              </div>`;
      },
    },
    {
      headerName: 'Invoice/Fatura',
      field: 'invoice',
      sortable: true,
      editable: false,
      filter: 'agTextColumnFilter',
      filterParams: { buttons: ['reset', 'apply'] },
      floatingFilter: true,
      hide: true,
      resizable: true,
      suppressMovable: true,
    },
    {
      headerName: 'Data Inv',
      field: 'invoiceDate',
      sortable: true,
      editable: false,
      filter: 'agDateColumnFilter',
      filterParams: {
        ...dateComparator,
        browserDatePicker: true,
      },
      floatingFilter: true,
      hide: true,
      resizable: true,
      suppressMovable: true,
      valueFormatter: (params) => (params.value ? moment(params.value).format('DD/MM/yyyy') : ''),
      cellEditor: DatePicker,
      cellEditorPopup: true,
    },
    {
      headerName: 'Ref Despachante',
      field: 'customBrokerRef',
      sortable: true,
      editable: true,
      filter: 'agTextColumnFilter',
      filterParams: { buttons: ['reset', 'apply'] },
      floatingFilter: true,
      hide: true,
      resizable: true,
      suppressMovable: true,
    },
    {
      headerName: 'Valor CIF',
      field: 'cifValue',
      sortable: true,
      editable: true,
      filter: 'agTextColumnFilter',
      filterParams: { buttons: ['reset', 'apply'] },
      floatingFilter: true,
      hide: true,
      resizable: true,
      suppressMovable: true,
    },
    {
      headerName: 'Moeda Valor CIF',
      field: 'cifValueCurrency',
      sortable: true,
      editable: true,
      filter: 'agTextColumnFilter',
      filterParams: { buttons: ['reset', 'apply'] },
      floatingFilter: true,
      hide: true,
      resizable: true,
      suppressMovable: true,
    },
    {
      headerName: 'Aplicação da Mercadoria',
      field: 'applicationOfGoods',
      sortable: true,
      editable: true,
      filter: 'agTextColumnFilter',
      filterParams: { buttons: ['reset', 'apply'] },
      floatingFilter: true,
      hide: true,
      resizable: true,
      suppressMovable: true,
    },
    {
      headerName: 'Benefício Fiscal',
      field: 'fiscalBenefit',
      sortable: true,
      editable: true,
      filter: 'agTextColumnFilter',
      filterParams: { buttons: ['reset', 'apply'] },
      floatingFilter: true,
      hide: true,
      resizable: true,
      suppressMovable: true,
    },
    {
      headerName: 'Descrição do Benefício Fiscal',
      field: 'fiscalBenefitDescription',
      sortable: true,
      editable: true,
      filter: 'agTextColumnFilter',
      filterParams: { buttons: ['reset', 'apply'] },
      floatingFilter: true,
      hide: true,
      resizable: true,
      suppressMovable: true,
    },
    {
      headerName: 'Descrição do ICMS',
      field: 'icmsDescription',
      sortable: true,
      editable: true,
      filter: 'agTextColumnFilter',
      filterParams: { buttons: ['reset', 'apply'] },
      floatingFilter: true,
      hide: true,
      resizable: true,
      suppressMovable: true,
    },
    {
      headerName: 'Incoterm',
      field: 'incoterm',
      sortable: true,
      editable: false,
      filter: 'agTextColumnFilter',
      filterParams: { buttons: ['reset', 'apply'] },
      floatingFilter: true,
      hide: true,
      resizable: true,
      suppressMovable: true,
    },
    {
      headerName: 'Via de Transporte',
      field: 'wayOfTransport',
      sortable: true,
      editable: false,
      filter: 'agTextColumnFilter',
      filterParams: { buttons: ['reset', 'apply'] },
      floatingFilter: true,
      hide: true,
      resizable: true,
      suppressMovable: true,
    },
    {
      headerName: 'Tipo de Embarque',
      field: 'typeOfBoarding',
      sortable: true,
      editable: false,
      filter: 'agTextColumnFilter',
      filterParams: { buttons: ['reset', 'apply'] },
      floatingFilter: true,
      hide: true,
      resizable: true,
      suppressMovable: true,
    },
    {
      headerName: 'BL/AWB/CRT',
      field: 'billOfLading',
      sortable: true,
      editable: false,
      filter: 'agTextColumnFilter',
      filterParams: { buttons: ['reset', 'apply'] },
      floatingFilter: true,
      hide: true,
      resizable: true,
      suppressMovable: true,
    },
    {
      headerName: 'Veículo / Navio',
      field: 'vehicle',
      sortable: true,
      editable: false,
      filter: 'agTextColumnFilter',
      filterParams: { buttons: ['reset', 'apply'] },
      floatingFilter: true,
      hide: true,
      resizable: true,
      suppressMovable: true,
    },
    {
      headerName: 'Local de Embarque',
      field: 'placeOfLoading',
      sortable: true,
      editable: false,
      filter: 'agTextColumnFilter',
      filterParams: { buttons: ['reset', 'apply'] },
      floatingFilter: true,
      hide: true,
      resizable: true,
      suppressMovable: true,
    },
    {
      headerName: 'Forwarder',
      field: 'forwarder',
      sortable: true,
      editable: false,
      filter: 'agTextColumnFilter',
      filterParams: { buttons: ['reset', 'apply'] },
      floatingFilter: true,
      valueFormatter: (params) => {
        if (params.data.freightForwarder) {
          return params.data.freightForwarder.name;
        }
        if (params.data.forwarder) {
          return params.data.forwarder;
        }
      },
      hide: true,
      resizable: true,
      suppressMovable: true,
    },
    {
      headerName: 'URF de Entrada',
      field: 'customsEnclosureURFEntrance.description',
      sortable: true,
      editable: true,
      filter: 'agTextColumnFilter',
      filterParams: { buttons: ['reset', 'apply'] },
      floatingFilter: true,
      hide: true,
      resizable: true,
      suppressMovable: true,
    },
    {
      headerName: 'Recinto Alfandegado',
      field: 'customsEnclosure.description',
      sortable: true,
      editable: true,
      filter: 'agTextColumnFilter',
      filterParams: { buttons: ['reset', 'apply'] },
      floatingFilter: true,
      hide: true,
      resizable: true,
      suppressMovable: true,
    },
    {
      headerName: 'Transportadora',
      field: 'conveyor.name',
      sortable: true,
      editable: false,
      filter: 'agTextColumnFilter',
      filterParams: { buttons: ['reset', 'apply'] },
      floatingFilter: true,
      hide: true,
      resizable: true,
      suppressMovable: true,
    },
    {
      headerName: 'Armazém Geral',
      field: 'generalWarehouse.name',
      sortable: true,
      editable: false,
      filter: 'agTextColumnFilter',
      filterParams: { buttons: ['reset', 'apply'] },
      floatingFilter: true,
      hide: true,
      resizable: true,
      suppressMovable: true,
    },
    {
      headerName: 'Nº CE-Mercante',
      field: 'ceMercante',
      sortable: true,
      editable: true,
      filter: 'agTextColumnFilter',
      filterParams: { buttons: ['reset', 'apply'] },
      floatingFilter: true,
      hide: true,
      resizable: true,
      suppressMovable: true,
    },
    {
      headerName: 'Valor da AFRMM',
      field: 'afrmmValue',
      sortable: true,
      editable: true,
      filter: 'agTextColumnFilter',
      filterParams: { buttons: ['reset', 'apply'] },
      floatingFilter: true,
      hide: true,
      resizable: true,
      suppressMovable: true,
    },
    {
      headerName: 'Situação do AFRMM',
      field: 'afrmmStatus',
      sortable: true,
      editable: true,
      filter: 'agTextColumnFilter',
      filterParams: { buttons: ['reset', 'apply'] },
      floatingFilter: true,
      hide: true,
      resizable: true,
      suppressMovable: true,
    },
    {
      headerName: 'Planilha',
      field: 'stepSpreadsheet',
      sortable: true,
      editable: true,
      filter: 'agDateColumnFilter',
      filterParams: {
        ...dateComparator,
        browserDatePicker: true,
      },
      floatingFilter: true,
      hide: true,
      resizable: true,
      suppressMovable: true,
      valueFormatter: (params) => (params.value ? moment(params.value).format('DD/MM/yyyy') : ''),
      cellEditor: DatePicker,
      cellEditorPopup: true,
    },
    {
      headerName: 'Seguro',
      field: 'stepInsurance',
      sortable: true,
      editable: true,
      filter: 'agDateColumnFilter',
      filterParams: {
        ...dateComparator,
        browserDatePicker: true,
      },
      floatingFilter: true,
      hide: true,
      resizable: true,
      suppressMovable: true,
      valueFormatter: (params) => (params.value ? moment(params.value).format('DD/MM/yyyy') : ''),
      cellEditor: DatePicker,
      cellEditorPopup: true,
      cellStyle: (params) => {
        if (params.data.stepInsuranceSolved !== true || params.data.stepInsuranceSolved == null) {
          if (!params.data.stepInsurance || params.data.stepInsurance === null) {
            if (params.data.datesETA) {
              const datePartsETA = params.data.datesETA.split('-');
              const cellDateETA = moment(new Date(datePartsETA[0], datePartsETA[1] - 1, datePartsETA[2].split('T')[0]), 'YYYY-MM-DD').startOf('day');

              if (cellDateETA.isValid()) {
                if (cellDateETA.diff(moment(), 'days') >= 0 && cellDateETA.diff(moment(), 'days') <= 7) {
                  return {
                    'background-color': '#e74c3c',
                    color: 'white',
                  };
                }
              }
            }
          }
        }
        return {};
      },
    },
    {
      headerName: 'Seguro Checkbox',
      field: 'stepInsuranceSolved',
      sortable: true,
      editable: true,
      filter: 'agTextColumnFilter',
      filterParams: { buttons: ['reset', 'apply'] },
      floatingFilter: true,
      hide: true,
      resizable: true,
      suppressMovable: true,
      valueFormatter: (params) => validateCheck(params),
    },
    {
      headerName: 'Valor do Seguro',
      field: 'stepInsuranceValue',
      sortable: true,
      editable: true,
      filter: 'agTextColumnFilter',
      filterParams: { buttons: ['reset', 'apply'] },
      floatingFilter: true,
      hide: true,
      resizable: true,
      suppressMovable: true,
    },
    {
      headerName: 'Responsabilidade do Seguro',
      field: 'stepInsuranceValueResponsability',
      sortable: true,
      editable: true,
      filter: 'agTextColumnFilter',
      filterParams: { buttons: ['reset', 'apply'] },
      floatingFilter: true,
      hide: true,
      resizable: true,
      suppressMovable: true,
    },
    {
      headerName: 'Numerário',
      field: 'stepCashRequest',
      sortable: true,
      editable: true,
      filter: 'agDateColumnFilter',
      filterParams: {
        ...dateComparator,
        browserDatePicker: true,
      },
      floatingFilter: true,
      hide: true,
      resizable: true,
      suppressMovable: true,
      valueFormatter: (params) => (params.value ? moment(params.value).format('DD/MM/yyyy') : ''),
      cellEditor: DatePicker,
      cellEditorPopup: true,
      cellStyle: (params) => {
        if (params.data.datesETD) {
          if (params.data.stepCashRequest === null || !params.data.stepCashRequest) {
            if (params.data.stepCashRequestSolved === null || params.data.stepCashRequestSolved !== true) {
              if (params.data.customer !== null && params.data.customer.numberOfDaysForCashNotification !== null) {
                const datePartsETD = params.data.datesETD.split('-');
                const cellDateETD = moment(new Date(datePartsETD[0], datePartsETD[1] - 1, datePartsETD[2].split('T')[0]), 'YYYY-MM-DD').startOf('day');

                const cellDateNotification = moment(moment().add(params.data.customer.numberOfDaysForCashNotification, 'days'), 'YYYY-MM-DD').startOf('day');

                if (cellDateETD.isValid() && cellDateNotification.isValid()) {
                  if (cellDateETD.diff(cellDateNotification, 'days') >= params.data.customer.numberOfDaysForCashNotification) {
                    return {
                      'background-color': '#2ecc71',
                      color: 'white',
                    };
                  }
                }
              }
            }
          }
        }
        return {};
      },
    },
    {
      headerName: 'Numerário Checkbox',
      field: 'stepCashRequestSolved',
      sortable: true,
      editable: true,
      filter: 'agTextColumnFilter',
      filterParams: { buttons: ['reset', 'apply'] },
      floatingFilter: true,
      hide: true,
      resizable: true,
      suppressMovable: true,
      valueFormatter: (params) => validateCheck(params),
    },
    {
      headerName: 'Originais',
      field: 'originalsDocumentsDate',
      sortable: true,
      editable: true,
      filter: 'agDateColumnFilter',
      filterParams: {
        ...dateComparator,
        browserDatePicker: true,
      },
      floatingFilter: true,
      hide: true,
      resizable: true,
      suppressMovable: true,
      valueFormatter: (params) => (params.value ? moment(params.value).format('DD/MM/yyyy') : ''),
      cellEditor: DatePicker,
      cellEditorPopup: true,
      cellStyle: (params) => {
        if (params.data.datesETA !== null) {
          if (params.data.originalsDocumentsDate === null || !params.data.originalsDocumentsDate) {
            if (params.data.originalsDocumentsDateSolved === null || !params.data.originalsDocumentsDateSolved) {
              const dateParts = params.data.datesETA.split('-');
              const cellDate = moment(new Date(dateParts[0], dateParts[1] - 1, dateParts[2].split('T')[0]), 'YYYY-MM-DD').startOf('day');

              if (cellDate.isValid()) {
                if (cellDate.diff(moment(new Date(), 'YYYY-MM-DD').startOf('day'), 'days') <= 14) {
                  return {
                    'background-color': '#e67e22',
                    color: 'white',
                  };
                }
              }
            }
          }
        }
        return {};
      },
    },
    {
      headerName: 'Originais Checkbox',
      field: 'originalsDocumentsDateSolved',
      sortable: true,
      editable: true,
      filter: 'agTextColumnFilter',
      filterParams: { buttons: ['reset', 'apply'] },
      floatingFilter: true,
      hide: true,
      resizable: true,
      suppressMovable: true,
      valueFormatter: (params) => validateCheck(params),
    },
    {
      headerName: 'Conhecimento',
      field: 'originalsBillOfLadingDate',
      sortable: true,
      editable: true,
      filter: 'agDateColumnFilter',
      filterParams: {
        ...dateComparator,
        browserDatePicker: true,
      },
      floatingFilter: true,
      hide: true,
      resizable: true,
      suppressMovable: true,
      valueFormatter: (params) => (params.value ? moment(params.value).format('DD/MM/yyyy') : ''),
      cellEditor: DatePicker,
      cellEditorPopup: true,
    },
    {
      headerName: 'Conhecimento Checkbox',
      field: 'originalsBillOfLadingDateSolved',
      sortable: true,
      editable: true,
      filter: 'agTextColumnFilter',
      filterParams: { buttons: ['reset', 'apply'] },
      floatingFilter: true,
      hide: true,
      resizable: true,
      suppressMovable: true,
      valueFormatter: (params) => validateCheck(params),
    },
    {
      headerName: 'Orig Fatura/Invoice',
      field: 'originalsInvoiceDate',
      sortable: true,
      editable: true,
      filter: 'agDateColumnFilter',
      filterParams: {
        ...dateComparator,
        browserDatePicker: true,
      },
      floatingFilter: true,
      hide: true,
      resizable: true,
      suppressMovable: true,
      valueFormatter: (params) => (params.value ? moment(params.value).format('DD/MM/yyyy') : ''),
      cellEditor: DatePicker,
      cellEditorPopup: true,
    },
    {
      headerName: 'Fatura / Invoice Checkbox',
      field: 'originalsInvoiceDateSolved',
      sortable: true,
      editable: true,
      filter: 'agTextColumnFilter',
      filterParams: { buttons: ['reset', 'apply'] },
      floatingFilter: true,
      hide: true,
      resizable: true,
      suppressMovable: true,
      valueFormatter: (params) => validateCheck(params),
    },
    {
      headerName: 'Packing List',
      field: 'originalsPackingListDate',
      sortable: true,
      editable: true,
      filter: 'agDateColumnFilter',
      filterParams: {
        ...dateComparator,
        browserDatePicker: true,
      },
      floatingFilter: true,
      hide: true,
      resizable: true,
      suppressMovable: true,
      valueFormatter: (params) => (params.value ? moment(params.value).format('DD/MM/yyyy') : ''),
      cellEditor: DatePicker,
      cellEditorPopup: true,
    },
    {
      headerName: 'Packing List Checkbox',
      field: 'originalsPackingListDateSolved',
      sortable: true,
      editable: true,
      filter: 'agTextColumnFilter',
      filterParams: { buttons: ['reset', 'apply'] },
      floatingFilter: true,
      hide: true,
      resizable: true,
      suppressMovable: true,
      valueFormatter: (params) => validateCheck(params),
    },
    {
      headerName: 'Conf Originais',
      field: 'conferenceDocumentsSolved',
      sortable: true,
      editable: true,
      filter: 'agTextColumnFilter',
      filterParams: { buttons: ['reset', 'apply'] },
      floatingFilter: true,
      hide: true,
      resizable: true,
      suppressMovable: true,
      valueFormatter: (params) => validateCheck(params),
    },
    {
      headerName: 'Conf Packing List',
      field: 'conferencePackingListSolved',
      sortable: true,
      editable: true,
      filter: 'agTextColumnFilter',
      filterParams: { buttons: ['reset', 'apply'] },
      floatingFilter: true,
      hide: true,
      resizable: true,
      suppressMovable: true,
      valueFormatter: (params) => validateCheck(params),
    },
    {
      headerName: 'Conf Conhecimento',
      field: 'conferenceBillOfLadingSolved',
      sortable: true,
      editable: true,
      filter: 'agTextColumnFilter',
      filterParams: { buttons: ['reset', 'apply'] },
      floatingFilter: true,
      hide: true,
      resizable: true,
      suppressMovable: true,
      valueFormatter: (params) => validateCheck(params),
    },
    {
      headerName: 'Conf Fatura / Invoice',
      field: 'conferenceInvoiceSolved',
      sortable: true,
      editable: true,
      filter: 'agTextColumnFilter',
      filterParams: { buttons: ['reset', 'apply'] },
      floatingFilter: true,
      hide: true,
      resizable: true,
      suppressMovable: true,
      valueFormatter: (params) => validateCheck(params),
    },
    {
      headerName: 'Número da DI',
      field: 'diNumber',
      sortable: true,
      editable: true,
      filter: 'agTextColumnFilter',
      filterParams: { buttons: ['reset', 'apply'] },
      floatingFilter: true,
      hide: true,
      resizable: true,
      suppressMovable: true,
      cellStyle: (params) => {
        if (params.data.datesArrivalDate !== null) {
          if (params.data.diNumber === null || !params.data.diNumber) {
            if (params.data.diRegistryDate === null || params.data.diRegistryDate !== true) {
              const dateParts = params.data.datesArrivalDate.split('-');
              const cellDate = moment(new Date(dateParts[0], dateParts[1] - 1, dateParts[2].split('T')[0]), 'YYYY-MM-DD').startOf('day');

              if (cellDate.isValid()) {
                if (cellDate.diff(moment().add(2, 'days').startOf('day'), 'days') < -2) {
                  return {
                    'background-color': '#e67e22',
                    color: 'white',
                  };
                }
              }
            }
          }
        }
        return {};
      },
    },
    {
      headerName: 'Registro DI',
      field: 'diRegistryDate',
      sortable: true,
      editable: true,
      filter: 'agDateColumnFilter',
      filterParams: {
        ...dateComparator,
        browserDatePicker: true,
      },
      floatingFilter: true,
      hide: true,
      resizable: true,
      suppressMovable: true,
      valueFormatter: (params) => (params.value ? moment(params.value).format('DD/MM/yyyy') : ''),
      cellEditor: DatePicker,
      cellEditorPopup: true,
    },
    {
      headerName: 'Digitação DI',
      field: 'diTypingDate',
      sortable: true,
      editable: true,
      filter: 'agDateColumnFilter',
      filterParams: {
        ...dateComparator,
        browserDatePicker: true,
      },
      floatingFilter: true,
      hide: true,
      resizable: true,
      suppressMovable: true,
      valueFormatter: (params) => (params.value ? moment(params.value).format('DD/MM/yyyy') : ''),
      cellEditor: DatePicker,
      cellEditorPopup: true,
    },
    {
      headerName: 'Desembaraço',
      field: 'diResourcefulnessDate',
      sortable: true,
      editable: true,
      filter: 'agDateColumnFilter',
      filterParams: {
        ...dateComparator,
        browserDatePicker: true,
      },
      floatingFilter: true,
      hide: true,
      resizable: true,
      suppressMovable: true,
      valueFormatter: (params) => (params.value ? moment(params.value).format('DD/MM/yyyy') : ''),
      cellEditor: DatePicker,
      cellEditorPopup: true,
    },
    {
      headerName: 'Canal',
      field: 'diChannel',
      sortable: true,
      editable: true,
      filter: 'agTextColumnFilter',
      filterParams: { buttons: ['reset', 'apply'] },
      floatingFilter: true,
      hide: true,
      resizable: true,
      suppressMovable: true,
    },
    {
      headerName: 'Notas Fiscais',
      field: 'billingNFsDate',
      sortable: true,
      editable: true,
      filter: 'agDateColumnFilter',
      filterParams: {
        ...dateComparator,
        browserDatePicker: true,
      },
      floatingFilter: true,
      hide: true,
      resizable: true,
      suppressMovable: true,
      valueFormatter: (params) => (params.value ? moment(params.value).format('DD/MM/yyyy') : ''),
      cellEditor: DatePicker,
      cellEditorPopup: true,
    },
    {
      headerName: 'Notas Fiscais Checkbox',
      field: 'billingNFsDateSolved',
      sortable: true,
      editable: true,
      filter: 'agTextColumnFilter',
      filterParams: { buttons: ['reset', 'apply'] },
      floatingFilter: true,
      hide: true,
      resizable: true,
      suppressMovable: true,
      valueFormatter: (params) => validateCheck(params),
    },
    {
      headerName: 'NF Emissão',
      field: 'billingNFsEmissionDate',
      sortable: true,
      editable: true,
      filter: 'agDateColumnFilter',
      filterParams: {
        ...dateComparator,
        browserDatePicker: true,
      },
      floatingFilter: true,
      hide: true,
      resizable: true,
      suppressMovable: true,
      valueFormatter: (params) => (params.value ? moment(params.value).format('DD/MM/yyyy') : ''),
      cellEditor: DatePicker,
      cellEditorPopup: true,
    },
    {
      headerName: 'NF Emissão Checkbox',
      field: 'billingNFsEmissionDateSolved',
      sortable: true,
      editable: true,
      filter: 'agTextColumnFilter',
      filterParams: { buttons: ['reset', 'apply'] },
      floatingFilter: true,
      hide: true,
      resizable: true,
      suppressMovable: true,
      valueFormatter: (params) => validateCheck(params),
    },
    {
      headerName: 'Faturamento Cliente',
      field: 'billingBillCustomerDate',
      sortable: true,
      editable: true,
      filter: 'agDateColumnFilter',
      filterParams: {
        ...dateComparator,
        browserDatePicker: true,
      },
      floatingFilter: true,
      hide: true,
      resizable: true,
      suppressMovable: true,
      valueFormatter: (params) => (params.value ? moment(params.value).format('DD/MM/yyyy') : ''),
      cellEditor: DatePicker,
      cellEditorPopup: true,
    },
    {
      headerName: 'Faturamento Cliente Checkbox',
      field: 'billingBillCustomerDateSolved',
      sortable: true,
      editable: true,
      filter: 'agTextColumnFilter',
      filterParams: { buttons: ['reset', 'apply'] },
      floatingFilter: true,
      hide: true,
      resizable: true,
      suppressMovable: true,
      valueFormatter: (params) => validateCheck(params),
    },
    {
      headerName: 'Email',
      field: 'billingEmailDate',
      sortable: true,
      editable: true,
      filter: 'agDateColumnFilter',
      filterParams: {
        ...dateComparator,
        browserDatePicker: true,
      },
      floatingFilter: true,
      hide: true,
      resizable: true,
      suppressMovable: true,
      valueFormatter: (params) => (params.value ? moment(params.value).format('DD/MM/yyyy') : ''),
      cellEditor: DatePicker,
      cellEditorPopup: true,
    },
    {
      headerName: 'Email Checkbox',
      field: 'billingEmailDateSolved',
      sortable: true,
      editable: true,
      filter: 'agTextColumnFilter',
      filterParams: { buttons: ['reset', 'apply'] },
      floatingFilter: true,
      hide: true,
      resizable: true,
      suppressMovable: true,
      valueFormatter: (params) => validateCheck(params),
    },
    {
      headerName: 'Envio',
      field: 'billingSendDate',
      sortable: true,
      editable: true,
      filter: 'agTextColumnFilter',
      filterParams: { buttons: ['reset', 'apply'] },
      floatingFilter: true,
      hide: true,
      resizable: true,
      suppressMovable: true,
    },
    {
      headerName: 'Enc Despachante',
      field: 'billingCustomBrokerFinishDate',
      sortable: true,
      editable: true,
      filter: 'agDateColumnFilter',
      filterParams: {
        ...dateComparator,
        browserDatePicker: true,
      },
      floatingFilter: true,
      hide: true,
      resizable: true,
      suppressMovable: true,
      valueFormatter: (params) => (params.value ? moment(params.value).format('DD/MM/yyyy') : ''),
      cellEditor: DatePicker,
      cellEditorPopup: true,
      cellStyle: (params) => {
        if (params.data.datesGoodsLoadingDate !== null) {
          if (params.data.billingCustomBrokerFinishDate === null || !params.data.billingCustomBrokerFinishDate) {
            if (params.data.billingCustomBrokerFinishDateSolved === null || params.data.billingCustomBrokerFinishDateSolved !== true) {
              const dateParts = params.data.datesGoodsLoadingDate.split('-');
              const cellDate = moment(new Date(dateParts[0], dateParts[1] - 1, dateParts[2].split('T')[0]), 'YYYY-MM-DD').startOf('day');

              if (cellDate.isValid()) {
                if (cellDate.diff(moment().add(3, 'days'), 'days') < -3) {
                  return {
                    'background-color': 'rgb(230, 126, 34)',
                    color: 'white',
                  };
                }
              }
            }
          }
        }
        return {};
      },
    },
    {
      headerName: 'Empresa',
      field: 'billingSendCompanyDescription',
      sortable: true,
      editable: true,
      filter: 'agTextColumnFilter',
      filterParams: { buttons: ['reset', 'apply'] },
      floatingFilter: true,
      hide: true,
      resizable: true,
      suppressMovable: true,
    },
    {
      headerName: 'Tracking',
      field: 'billingSendTrackingDescription',
      sortable: true,
      editable: true,
      filter: 'agTextColumnFilter',
      filterParams: { buttons: ['reset', 'apply'] },
      floatingFilter: true,
      hide: true,
      resizable: true,
      suppressMovable: true,
    },
    {
      headerName: 'Conferência de Dados',
      field: 'billingDataConferenceDate',
      sortable: true,
      editable: true,
      filter: 'agDateColumnFilter',
      filterParams: {
        ...dateComparator,
        browserDatePicker: true,
      },
      floatingFilter: true,
      hide: true,
      resizable: true,
      suppressMovable: true,
      valueFormatter: (params) => (params.value ? moment(params.value).format('DD/MM/yyyy') : ''),
      cellEditor: DatePicker,
      cellEditorPopup: true,
    },
    {
      headerName: 'Prontidão (Prev)',
      field: 'datesEstimatedGoodsReadinesDate',
      sortable: true,
      editable: true,
      filter: 'agDateColumnFilter',
      filterParams: {
        ...dateComparator,
        browserDatePicker: true,
      },
      floatingFilter: true,
      hide: true,
      resizable: true,
      suppressMovable: true,
      valueFormatter: (params) => (params.value ? moment(params.value).format('DD/MM/yyyy') : ''),
      cellEditor: DatePicker,
      cellEditorPopup: true,
    },
    {
      headerName: 'ETD',
      field: 'datesETD',
      sortable: true,
      editable: true,
      filter: 'agDateColumnFilter',
      filterParams: {
        ...dateComparator,
        browserDatePicker: true,
      },
      floatingFilter: true,
      hide: true,
      resizable: true,
      suppressMovable: true,
      valueFormatter: (params) => (params.value ? moment(params.value).format('DD/MM/yyyy') : ''),
      cellEditor: DatePicker,
      cellEditorPopup: true,
    },
    {
      headerName: 'ETA',
      field: 'datesETA',
      sortable: true,
      editable: true,
      filter: 'agDateColumnFilter',
      filterParams: {
        ...dateComparator,
        browserDatePicker: true,
      },
      floatingFilter: true,
      hide: true,
      resizable: true,
      suppressMovable: true,
      valueFormatter: (params) => (params.value ? moment(params.value).format('DD/MM/yyyy') : ''),
      cellEditor: DatePicker,
      cellEditorPopup: true,
      cellStyle: (params) => {
        if (params.data.datesETA) {
          const dateParts = params.data.datesETA.split('-');
          const cellDate = moment(new Date(dateParts[0], dateParts[1] - 1, dateParts[2].split('T')[0]), 'YYYY-MM-DD').startOf('day');

          if (cellDate.isValid()) {
            if (cellDate.diff(sevenDaysAhead, 'days') > 0 && cellDate.diff(fourteenDaysAhead, 'days') <= 0) {
              return {
                'background-color': 'rgb(241, 196, 15)',
                color: 'black',
              };
            }
            if (cellDate.diff(today, 'days') > 0 && cellDate.diff(sevenDaysAhead, 'days') <= 0) {
              return {
                'background-color': 'rgb(231, 76, 60)',
                color: 'white',
              };
            }
            if (cellDate.diff(today, 'days') === 0) {
              return {
                'background-color': 'rgb(155, 89, 182)',
                color: 'white',
              };
            }
          }
        }
        return {};
      },
    },
    {
      headerName: 'Entrega (P)',
      field: 'datesEstimatedCustomerDeliverDate',
      sortable: true,
      editable: true,
      filter: 'agDateColumnFilter',
      filterParams: {
        ...dateComparator,
        browserDatePicker: true,
      },
      floatingFilter: true,
      hide: true,
      resizable: true,
      suppressMovable: true,
      valueFormatter: (params) => (params.value ? moment(params.value).format('DD/MM/yyyy') : ''),
      cellEditor: DatePicker,
      cellEditorPopup: true,
    },
    {
      headerName: 'Prontidão',
      field: 'datesGoodsReadinesDate',
      sortable: true,
      editable: true,
      filter: 'agDateColumnFilter',
      filterParams: {
        ...dateComparator,
        browserDatePicker: true,
      },
      floatingFilter: true,
      hide: true,
      resizable: true,
      suppressMovable: true,
      valueFormatter: (params) => (params.value ? moment(params.value).format('DD/MM/yyyy') : ''),
      cellEditor: DatePicker,
      cellEditorPopup: true,
    },
    {
      headerName: 'Embarque',
      field: 'datesDepartureDate',
      sortable: true,
      editable: true,
      filter: 'agDateColumnFilter',
      filterParams: {
        ...dateComparator,
        browserDatePicker: true,
      },
      floatingFilter: true,
      hide: true,
      resizable: true,
      suppressMovable: true,
      valueFormatter: (params) => (params.value ? moment(params.value).format('DD/MM/yyyy') : ''),
      cellEditor: DatePicker,
      cellEditorPopup: true,
    },
    {
      headerName: 'Chegada',
      field: 'datesArrivalDate',
      sortable: true,
      editable: true,
      filter: 'agDateColumnFilter',
      filterParams: {
        ...dateComparator,
        browserDatePicker: true,
      },
      floatingFilter: true,
      hide: true,
      resizable: true,
      suppressMovable: true,
      valueFormatter: (params) => (params.value ? moment(params.value).format('DD/MM/yyyy') : ''),
      cellEditor: DatePicker,
      cellEditorPopup: true,
    },
    {
      headerName: 'Presença de Carga',
      field: 'datesGoodsPresenceDate',
      sortable: true,
      editable: true,
      filter: 'agDateColumnFilter',
      filterParams: {
        ...dateComparator,
        browserDatePicker: true,
      },
      floatingFilter: true,
      hide: true,
      resizable: true,
      suppressMovable: true,
      valueFormatter: (params) => (params.value ? moment(params.value).format('DD/MM/yyyy') : ''),
      cellEditor: DatePicker,
      cellEditorPopup: true,
    },
    {
      headerName: 'Vistoria da DI',
      field: 'datesInspectionOfTheDIDate',
      sortable: true,
      editable: true,
      filter: 'agDateColumnFilter',
      filterParams: {
        ...dateComparator,
        browserDatePicker: true,
      },
      floatingFilter: true,
      hide: true,
      resizable: true,
      suppressMovable: true,
      valueFormatter: (params) => (params.value ? moment(params.value).format('DD/MM/yyyy') : ''),
      cellEditor: DatePicker,
      cellEditorPopup: true,
    },
    {
      headerName: 'Carregamento',
      field: 'datesGoodsLoadingDate',
      sortable: true,
      editable: true,
      filter: 'agDateColumnFilter',
      filterParams: {
        ...dateComparator,
        browserDatePicker: true,
      },
      floatingFilter: true,
      hide: true,
      resizable: true,
      suppressMovable: true,
      valueFormatter: (params) => (params.value ? moment(params.value).format('DD/MM/yyyy') : ''),
      cellEditor: DatePicker,
      cellEditorPopup: true,
      cellStyle: (params) => {
        if (params.data.diResourcefulnessDate !== null || params.data.diResourcefulnessDate === true) {
          if (params.data.datesGoodsLoadingDate === null || !params.data.datesGoodsLoadingDate) {
            const dateParts = params.data.diResourcefulnessDate.split('-');
            const cellDate = moment(new Date(dateParts[0], dateParts[1] - 1, dateParts[2].split('T')[0]), 'YYYY-MM-DD').startOf('day');

            if (cellDate.diff(moment().startOf('day')) < -2) {
              return {
                'background-color': '#e67e22',
                color: 'white',
              };
            }
          }
        }
        return {};
      },
    },
    {
      headerName: 'Entrega',
      field: 'datesCustomerDeliverDate',
      sortable: true,
      editable: true,
      filter: 'agDateColumnFilter',
      filterParams: {
        ...dateComparator,
        browserDatePicker: true,
      },
      floatingFilter: true,
      hide: true,
      resizable: true,
      suppressMovable: true,
      valueFormatter: (params) => (params.value ? moment(params.value).format('DD/MM/yyyy') : ''),
      cellEditor: DatePicker,
      cellEditorPopup: true,
      cellStyle: (params) => {
        if (params.data.datesGoodsLoadingDate !== null) {
          if (params.data.datesCustomerDeliverDate === null || !params.data.datesCustomerDeliverDate) {
            const dateParts = params.data.datesGoodsLoadingDate.split('-');
            const cellDate = moment(new Date(dateParts[0], dateParts[1] - 1, dateParts[2].split('T')[0]), 'YYYY-MM-DD').startOf('day');

            if (cellDate.isValid()) {
              if (cellDate.diff(moment(new Date(), 'YYYY-MM-DD').add(1, 'days').startOf('day'), 'days') <= 1) {
                return {
                  'background-color': '#e67e22',
                  color: 'white',
                };
              }
            }
          }
        }
        return {};
      },
    },
    {
      headerName: 'Vistoria MAPA',
      field: 'datesInspectionMAPADate',
      sortable: true,
      editable: true,
      filter: 'agDateColumnFilter',
      filterParams: {
        ...dateComparator,
        browserDatePicker: true,
      },
      floatingFilter: true,
      hide: true,
      resizable: true,
      suppressMovable: true,
      valueFormatter: (params) => (params.value ? moment(params.value).format('DD/MM/yyyy') : ''),
      cellEditor: DatePicker,
      cellEditorPopup: true,
    },
    {
      headerName: 'Vistoria MAPA Checkbox',
      field: 'datesInspectionMAPADateSolved',
      sortable: true,
      editable: true,
      filter: 'agTextColumnFilter',
      filterParams: { buttons: ['reset', 'apply'] },
      floatingFilter: true,
      hide: true,
      resizable: true,
      suppressMovable: true,
      valueFormatter: (params) => validateCheck(params),
    },
    {
      headerName: 'Liberação MAPA',
      field: 'datesReleaseMAPADate',
      sortable: true,
      editable: true,
      filter: 'agDateColumnFilter',
      filterParams: {
        ...dateComparator,
        browserDatePicker: true,
      },
      floatingFilter: true,
      hide: true,
      resizable: true,
      suppressMovable: true,
      valueFormatter: (params) => (params.value ? moment(params.value).format('DD/MM/yyyy') : ''),
      cellEditor: DatePicker,
      cellEditorPopup: true,
    },
    {
      headerName: 'Liberação MAPA Checkbox',
      field: 'datesReleaseMAPADateSolved',
      sortable: true,
      editable: true,
      filter: 'agTextColumnFilter',
      filterParams: { buttons: ['reset', 'apply'] },
      floatingFilter: true,
      hide: true,
      resizable: true,
      suppressMovable: true,
      valueFormatter: (params) => validateCheck(params),
    },
    {
      headerName: 'Vínculo no Recinto',
      field: 'datesLinkInTheEnclosureDate',
      sortable: true,
      editable: true,
      filter: 'agDateColumnFilter',
      filterParams: {
        ...dateComparator,
        browserDatePicker: true,
      },
      floatingFilter: true,
      hide: true,
      resizable: true,
      suppressMovable: true,
      valueFormatter: (params) => (params.value ? moment(params.value).format('DD/MM/yyyy') : ''),
      cellEditor: DatePicker,
      cellEditorPopup: true,
    },
    {
      headerName: 'Vínculo no Recinto Checkbox',
      field: 'datesLinkInTheEnclosureDateSolved',
      sortable: true,
      editable: true,
      filter: 'agTextColumnFilter',
      filterParams: { buttons: ['reset', 'apply'] },
      floatingFilter: true,
      hide: true,
      resizable: true,
      suppressMovable: true,
      valueFormatter: (params) => validateCheck(params),
    },
    {
      headerName: 'Liberação de AWB/BL',
      field: 'datesBillOfLadingReleaseDate',
      sortable: true,
      editable: true,
      filter: 'agDateColumnFilter',
      filterParams: {
        ...dateComparator,
        browserDatePicker: true,
      },
      floatingFilter: true,
      hide: true,
      resizable: true,
      suppressMovable: true,
      valueFormatter: (params) => (params.value ? moment(params.value).format('DD/MM/yyyy') : ''),
      cellEditor: DatePicker,
      cellEditorPopup: true,
    },
    {
      headerName: 'Siscarga',
      field: 'datesSiscargaState',
      sortable: true,
      editable: true,
      filter: 'agTextColumnFilter',
      filterParams: { buttons: ['reset', 'apply'] },
      floatingFilter: true,
      hide: true,
      resizable: true,
      suppressMovable: true,
    },
    {
      headerName: 'Data do Siscarga',
      field: 'datesSiscargaDate',
      sortable: true,
      editable: true,
      filter: 'agDateColumnFilter',
      filterParams: {
        ...dateComparator,
        browserDatePicker: true,
      },
      floatingFilter: true,
      hide: true,
      resizable: true,
      suppressMovable: true,
      valueFormatter: (params) => (params.value ? moment(params.value).format('DD/MM/yyyy') : ''),
      cellEditor: DatePicker,
      cellEditorPopup: true,
    },
    {
      headerName: 'DTA/DTC?',
      field: 'datesIsThereDTCDTATransfer',
      sortable: true,
      editable: true,
      filter: 'agTextColumnFilter',
      filterParams: { buttons: ['reset', 'apply'] },
      floatingFilter: true,
      hide: true,
      resizable: true,
      suppressMovable: true,
      cellStyle: (params) => {
        if (params.data.datesIsThereDTCDTATransfer !== null && params.data.datesIsThereDTCDTATransfer === true) {
          if (params.data.datesDTCDTA === null || !params.data.datesDTCDTA) {
            return {
              'background-color': '#e67e22',
              color: 'white',
            };
          }
        }
        return {};
      },
    },
    {
      headerName: 'Data DTA/DTC',
      field: 'datesDTCDTA',
      sortable: true,
      editable: true,
      filter: 'agDateColumnFilter',
      filterParams: {
        ...dateComparator,
        browserDatePicker: true,
      },
      floatingFilter: true,
      hide: true,
      resizable: true,
      suppressMovable: true,
      valueFormatter: (params) => (params.value ? moment(params.value).format('DD/MM/yyyy') : ''),
      cellEditor: DatePicker,
      cellEditorPopup: true,
      cellStyle: (params) => {
        if (params.data.datesIsThereDTCDTATransfer !== null && params.data.datesIsThereDTCDTATransfer === true) {
          if (params.data.datesDTCDTA === null || !params.data.datesDTCDTA) {
            return {
              'background-color': '#e67e22',
              color: 'white',
            };
          }
        }
        return {};
      },
    },
    {
      headerName: 'Desova',
      field: 'datesThereIsDesova',
      sortable: true,
      editable: true,
      filter: 'agTextColumnFilter',
      filterParams: { buttons: ['reset', 'apply'] },
      floatingFilter: true,
      hide: true,
      resizable: true,
      suppressMovable: true,
    },
    {
      headerName: 'Faturamento Recinto',
      field: 'datesEnclosureBillingDate',
      sortable: true,
      editable: true,
      filter: 'agDateColumnFilter',
      filterParams: {
        ...dateComparator,
        browserDatePicker: true,
      },
      floatingFilter: true,
      hide: true,
      resizable: true,
      suppressMovable: true,
      valueFormatter: (params) => (params.value ? moment(params.value).format('DD/MM/yyyy') : ''),
      cellEditor: DatePicker,
      cellEditorPopup: true,
    },
    {
      headerName: 'Comex',
      field: 'datesComexDate',
      sortable: true,
      editable: true,
      filter: 'agDateColumnFilter',
      filterParams: {
        ...dateComparator,
        browserDatePicker: true,
      },
      floatingFilter: true,
      hide: true,
      resizable: true,
      suppressMovable: true,
      valueFormatter: (params) => (params.value ? moment(params.value).format('DD/MM/yyyy') : ''),
      cellEditor: DatePicker,
      cellEditorPopup: true,
    },
    {
      headerName: 'Abertura',
      field: 'created',
      sortable: true,
      editable: false,
      filter: 'agDateColumnFilter',
      filterParams: {
        ...dateComparator,
        browserDatePicker: true,
      },
      floatingFilter: true,
      hide: true,
      resizable: true,
      suppressMovable: true,
      valueFormatter: (params) => (params.value ? moment(params.value).format('DD/MM/yyyy') : ''),
      cellEditor: DatePicker,
      cellEditorPopup: true,
    },
    {
      headerName: 'Abertura (Antigo)',
      field: 'legacyCreated',
      sortable: true,
      editable: true,
      filter: 'agDateColumnFilter',
      filterParams: {
        ...dateComparator,
        browserDatePicker: true,
      },
      floatingFilter: true,
      hide: true,
      resizable: true,
      suppressMovable: true,
      valueFormatter: (params) => (params.value ? moment(params.value).format('DD/MM/yyyy') : ''),
      cellEditor: DatePicker,
      cellEditorPopup: true,
    },
    {
      headerName: 'Deadline (Freetime)',
      field: 'freetime',
      sortable: true,
      editable: false,
      filter: 'agTextColumnFilter',
      filterParams: { buttons: ['reset', 'apply'] },
      floatingFilter: true,
      hide: true,
      resizable: true,
      suppressMovable: true,
    },
    {
      headerName: 'Cliente (Comissão Mínima)',
      field: 'customer.isMinimalComissionPercentual',
      sortable: true,
      editable: false,
      filter: 'agTextColumnFilter',
      filterParams: { buttons: ['reset', 'apply'] },
      floatingFilter: true,
      hide: true,
      resizable: true,
      suppressMovable: true,
    },
  ],
  stepColumns: {
    /* todos */
    8: ['reminder', 'identification', 'step', 'customer', 'exporter.name', 'customerRef', 'anotherRef', 'status', 'statusCustomBroker', 'duimp', 'invoice', 'incoterm', 'typeOfBoarding', 'datesEstimatedGoodsReadinesDate', 'forwarder', 'datesETD', 'datesETA'],
    /* producao */
    7: ['reminder', 'datesETD', 'datesETA', 'identification', 'step', 'status', 'statusCustomBroker', 'customerRef', 'customer', 'exporter', 'duimp', 'invoice', 'incoterm', 'typeOfBoarding', 'stepSpreadsheet', 'stepInsurance', 'datesEstimatedGoodsReadinesDate'],
    /* licenciamento */
    11: ['reminder', 'identification', 'step', 'status', 'statusCustomBroker', 'customerRef', 'customer', 'exporter', 'customBroker', 'duimp', 'invoice', 'incoterm', 'wayOfTransport', 'typeOfBoarding', 'billOfLading', 'forwarder', 'customsEnclosure', 'stepSpreadsheet', 'stepInsurance', 'datesEstimatedGoodsReadinesDate', 'datesETD', 'datesETA'],
    /* booking */
    1: ['reminder', 'identification', 'step', 'status', 'statusCustomBroker', 'customerRef', 'customer', 'exporter', 'customBroker', 'duimp', 'invoice', 'incoterm', 'wayOfTransport', 'typeOfBoarding', 'billOfLading', 'forwarder', 'customsEnclosure', 'stepSpreadsheet', 'stepInsurance', 'datesEstimatedGoodsReadinesDate', 'datesETD', 'datesETA'],
    /* transito */
    2: ['reminder', 'identification', 'step', 'status', 'statusCustomBroker', 'customerRef', 'customer', 'exporter', 'customBroker', 'duimp', 'invoice', 'incoterm', 'typeOfBoarding', 'billOfLading', 'vehicle', 'forwarder', 'customsEnclosure', 'stepCashRequest', 'originalsDocumentsDate', 'datesETA', 'freetime', 'datesDepartureDate', 'datesIsThereDTCDTATransfer'],
    /* desembaraco */
    3: ['reminder', 'identification', 'step', 'status', 'statusCustomBroker', 'customerRef', 'customer', 'exporter', 'customBroker', 'duimp', 'invoice', 'typeOfBoarding', 'billOfLading', 'customsEnclosure', 'conveyor', 'stepCashRequest', 'diNumber', 'diRegistryDate', 'diChannel', 'datesArrivalDate', 'freetime', 'datesGoodsPresenceDate'],
    /* carregamento */
    4: ['reminder', 'identification', 'step', 'status', 'statusCustomBroker', 'customerRef', 'customer', 'exporter', 'invoice', 'duimp', 'typeOfBoarding', 'customsEnclosure', 'conveyor', 'diResourcefulnessDate', 'billingNFsDate', 'billingNFsEmissionDate', 'datesArrivalDate', 'freetime', 'datesSiscargaState', 'datesComexDate'],
    /* encerramento */
    5: ['reminder', 'identification', 'step', 'customer', 'customerRef', 'status', 'statusCustomBroker', 'customBroker', 'duimp', 'typeOfBoarding', 'customsEnclosure', 'datesCustomerDeliverDate'],
    /* pendencias */
    10: ['reminder', 'identification', 'step', 'status', 'statusCustomBroker', 'customerRef', 'customer', 'exporter', 'duimp', 'invoice', 'incoterm', 'typeOfBoarding', 'conveyor'],
    /* concluido */
    6: ['reminder', 'identification', 'step', 'status', 'statusCustomBroker', 'customerRef', 'customer', 'exporter', 'duimp', 'invoice', 'incoterm', 'typeOfBoarding', 'billOfLading', 'diNumber', 'diResourcefulnessDate', 'diChannel', 'billingBillCustomerDate', 'datesDepartureDate', 'datesArrivalDate', 'datesGoodsLoadingDate', 'datesCustomerDeliverDate'],
    /* cancelamento */
    9: ['reminder', 'identification', 'step', 'status', 'statusCustomBroker', 'customerRef', 'customer', 'exporter', 'duimp', 'invoice', 'incoterm', 'typeOfBoarding', 'billOfLading', 'datesDepartureDate', 'datesArrivalDate', 'billOfLading', 'diNumber', 'diResourcefulnessDate', 'diChannel', 'datesGoodsLoadingDate', 'datesCustomerDeliverDate', 'billingBillCustomerDate'],
  },
  socket: null,
  openedProcesses: [],
};

export default teste;
