<template>
  <svg :width="size" :height="size" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.0363 18L8.26133 12.125L4.83633 18H0.886328L6.38633 9.2L0.811328 0.55H4.83633L8.61133 6.4L12.0113 0.55H15.9613L10.4863 9.325L16.0613 18H12.0363Z" fill="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: 'CloseIcon',
  props: {
    size: {
      type: String,
      default: '1.5rem',
    },
  },
};
</script>
