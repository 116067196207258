<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" width="size" :height="size" fill="#none"><path d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z" fill="currentColor"/></svg>
</template>

<script>
export default {
  name: 'CloseMSIcon',
  props: {
    size: {
      type: String,
      default: '1.5rem',
    },
  },
};
</script>
