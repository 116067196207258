<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 0.375C5.57812 0.375 0.375 5.57812 0.375 12C0.375 18.4219 5.57812 23.625 12 23.625C18.4219 23.625 23.625 18.4219 23.625 12C23.625 5.57812 18.4219 0.375 12 0.375ZM17.7 15.0516C17.9203 15.2719 17.9203 15.6281 17.7 15.8484L15.8438 17.7C15.6234 17.9203 15.2672 17.9203 15.0469 17.7L12 14.625L8.94844 17.7C8.72812 17.9203 8.37187 17.9203 8.15156 17.7L6.3 15.8438C6.07969 15.6234 6.07969 15.2672 6.3 15.0469L9.375 12L6.3 8.94844C6.07969 8.72812 6.07969 8.37187 6.3 8.15156L8.15625 6.29531C8.37656 6.075 8.73281 6.075 8.95312 6.29531L12 9.375L15.0516 6.3C15.2719 6.07969 15.6281 6.07969 15.8484 6.3L17.7047 8.15625C17.925 8.37656 17.925 8.73281 17.7047 8.95312L14.625 12L17.7 15.0516Z" fill="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: 'NotAllowedIcon',
  props: {
    size: {
      type: String,
      default: '1.5rem',
    },
  },
};
</script>
