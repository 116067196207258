<template>
  <div class="history-item ph-1 pv-2">
    <div class="history-header flex ai-c fgap2 mb-2">
      <div class="history-indicator">{{ initials }}</div>
      <div>
        <p class="text-medium">{{ data.personName }}</p>
        <p class="text-light">{{ data.date | moment("DD/MM/YYYY") }}</p>
      </div>
    </div>

    <div class="history-content">
      <p class="text-medium">{{ data.title }}</p>
      <p class="text-light">{{ data.description }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HistoryItem',
  props: ['data'],
  computed: {
    initials() {
      if (this.data.personName.split(' ').length > 1) {
        return this.data.personName.split(' ')[0].split('')[0] + this.data.personName.split(' ')[1].split('')[0];
      }
      return this.data.personName.split(' ')[0].split('')[0];
    },
  },
};
</script>

<style scoped>
  .history-indicator {
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 1.5rem;
    font-weight: bold;
    width: 47px;
    height: 47px;
    border-radius: var(--big-border-radius);
    background-color: var(--primary-color);
    overflow: hidden;
  }
</style>
